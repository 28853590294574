import {useTranslation} from "react-i18next";
import {useState, useContext} from "react";
import report_img from "../../images/report/report.png";
import spam_img from "../../images/report/spam.png";
import violence_img from "../../images/report/violence.png";
import adult_img from "../../images/report/adult.png";
import translation_img from "../../images/profile/language.png";
import position_img from "../../images/report/position.png";
import {createPortal} from "react-dom";
import {useFetching} from "../../../hooks/useFetching";
import {ReportMemeView, TitleReportView} from "../../../views/ReportMeme";
import {LyingSeparator} from "../Separator";
import cl from "./Btn.module.css"
import {toast} from "react-toastify";
import MemeBattleService from "../../../services/MemeBattleService";
import {toModelReportMeme, toModelTitleReport} from "../../../converter/Report";
import {observer} from "mobx-react-lite";
import { Context } from "../../..";

interface ReportBtnProps {
    memeId: number,
    displayTitle?: boolean,
    doWhenMemeReport?(): Promise<void>,
}

export function ReportBtn({memeId, displayTitle, doWhenMemeReport}: ReportBtnProps) {
    const { t } = useTranslation();
    const [isModal, setIsModal] = useState(false);
    // const [isTitleReported, setIsTitleReported] = useState(false);
    // const [isMemeReported, setIsMemeReported] = useState(false);
    // const notify = () => toast.success(t("report.successSending"), {autoClose: 1000});

    return (
        <>
            <div className={cl.MemePanelBtn}
                 // onClick={() => setIsModal(!(isMemeReported && isTitleReported))}
                 onClick={() => setIsModal(true)}
                 // style={(isMemeReported && isTitleReported) ? {cursor: "default"} : {cursor: "pointer"}}>
                 >
                <img className={cl.MemePanelBtnImg}
                     src={report_img} alt={"report_img"}
                />
                { displayTitle && <div className={cl.MemePanelBtnTitle}>{t("report.popUpTitle")}</div> }
            </div>
            { createPortal(
                <ReportOptions memeId={memeId}
                               isVisible={isModal}
                               setIsVisible={setIsModal}
                               doWhenMemeReport={doWhenMemeReport}
                    // isTitleReported={isTitleReported}
                    // isMemeReported={isMemeReported}
                    // setIsTitleReported={(b) => {
                    //     setIsTitleReported(b);
                    //     if (b) notify();
                    // }}
                    // setIsMemeReported={(b) => {
                    //     setIsMemeReported(b);
                    //     if (b) notify();
                    // }}
                />,
                document.body
            )}
        </>
    );
}

interface ReportOptionsProps {
    memeId: number,
    isVisible: boolean,
    setIsVisible(b: boolean): void,
    doWhenMemeReport?(): Promise<void>,
    // isTitleReported: boolean,
    // setIsTitleReported(b: boolean): void,
    // isMemeReported: boolean,
    // setIsMemeReported(b: boolean): void,
}

const ReportOptions = observer((props: ReportOptionsProps) => {
    // console.log(props.isVisible)
    const { t } = useTranslation();
    const { localeStore } = useContext(Context);
    const notify = () => toast.success(t("report.successSending"), {autoClose: 1000});
    const [sendMemeReport] = useFetching(async (report: ReportMemeView) => {
        // if (props.isMemeReported) {
        //     return;
        // }
        // props.setIsMemeReported(true);
        props.setIsVisible(false);
        notify();
        if (props.doWhenMemeReport) {
            await props.doWhenMemeReport();
        }
        await MemeBattleService.reportMeme(toModelReportMeme(report));
    })

    const [sendTitleReport] = useFetching(async (report: TitleReportView) => {
        // if (props.isTitleReported) {
        //     return;
        // }
        // props.setIsTitleReported(true);
        props.setIsVisible(false);
        notify();
        await MemeBattleService.titleReport(toModelTitleReport(report));
    })

    // let titleReportOptCl = cl.ReportOption
    let titleReportOptCl = cl.ReportOption + " " + cl.clickable
    // let memeReportOptCl = cl.ReportOption
    let memeReportOptCl = cl.ReportOption + " " + cl.clickable
    // if (!props.isTitleReported) {
    //     titleReportOptCl += " " + cl.active
    // }
    // if (!props.isMemeReported) {
    //     memeReportOptCl += " " + cl.active
    // }

    return (
        <>
            <div className={`${cl.ModalBG} ${props.isVisible ? cl.active : ""}`}
                 onMouseDown={() => props.setIsVisible(false)}>
                <div className={cl.ModalContainer}
                     onMouseDown={(e) => e.stopPropagation()}>
                    <div className={cl.ModalTitle}>{t("report.modalTitle")}</div>
                    <LyingSeparator/>
                    <div className={memeReportOptCl}
                         onClick={() => sendMemeReport({
                            meme_id: props.memeId, 
                            category: "spam", 
                            lang: localeStore.getMemeLanguage(),
                            reg: localeStore.getRegion(),
                            })}>
                        <img className={cl.ReportOptionImg}
                             src={spam_img} alt={"spam_img"}/>
                        <div>{t("report.spam")}</div>
                    </div>
                    <LyingSeparator/>
                    <div className={memeReportOptCl}
                         onClick={() => sendMemeReport({
                            meme_id: props.memeId, 
                            category: "violenceOrHostility", 
                            lang: localeStore.getMemeLanguage(),
                            reg: localeStore.getRegion(),
                            })}>
                        <img className={cl.ReportOptionImg}
                             src={violence_img} alt={"violence_img"}/>
                        <div>{t("report.violenceOrHostility")}</div>
                    </div>
                    <LyingSeparator/>
                    <div className={memeReportOptCl}
                         onClick={() => sendMemeReport({
                            meme_id: props.memeId, 
                            category: "adultContent",
                            lang: localeStore.getMemeLanguage(),
                            reg: localeStore.getRegion(),
                            })}>
                        <img className={cl.ReportOptionImg}
                             src={adult_img} alt={"adult_img"}/>
                        <div>{t("report.adultContent")}</div>
                    </div>
                    <LyingSeparator/>
                    <div className={titleReportOptCl}
                         onClick={() => sendTitleReport({
                            meme_id: props.memeId, 
                            category: "translation",
                            lang: localeStore.getMemeLanguage(),
                            reg: localeStore.getRegion(),
                            })}>
                        <img className={cl.ReportOptionImg}
                             src={translation_img} alt={"translation_img"}/>
                        <div>{t("report.translation")}</div>
                    </div>
                    <LyingSeparator/>
                    <div className={titleReportOptCl}
                         onClick={() => sendTitleReport({
                            meme_id: props.memeId, 
                            category: "position",
                            lang: localeStore.getMemeLanguage(),
                            reg: localeStore.getRegion(),
                            })}>
                        <img className={cl.ReportOptionImg}
                             src={position_img} alt={"position_img"}/>
                        <div>{t("report.position")}</div>
                    </div>
                    <LyingSeparator/>
                    <div className={memeReportOptCl}
                         onClick={() => sendMemeReport({
                            meme_id: props.memeId, 
                            category: "other",
                            lang: localeStore.getMemeLanguage(),
                            reg: localeStore.getRegion(),
                            })}>
                        <img className={cl.ReportOptionImg}
                             src={report_img} alt={"report_img"}/>
                        <div>{t("report.other")}</div>
                    </div>
                </div>
            </div>
        </>
    );
})