export interface Language {
    code: string, name: string
}

export interface Region {
    code: string, name: string
}

export const globalRegionCode = "GLOBAL"
export const planetEarthRegionCode = "EA"

export interface Locale {
    langCode: string,
    regCode: string,
}