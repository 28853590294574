import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {AuthResponse} from "../models/Auth";
import {config} from "../config";
import {fingerprint} from "../App";
import {accessTokenName} from "../store/authStore";
import {authStore} from "../index";

export const SiteURL = config.SiteURL;
export const ServerURL = config.ServerURL;
export const API_URL = ServerURL + "/api";

const $api = axios.create({
    withCredentials : true,
    baseURL: API_URL,
})

let isRefreshingToken = false;
let requestsQueue: Array<{ config: InternalAxiosRequestConfig, resolve: (value: AxiosResponse) => void, reject: (error?: AxiosError) => void }> = [];

const processQueue = (): void => {
    requestsQueue.forEach(({ config, resolve, reject }) => {
        $api.request(config)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
    requestsQueue = [];
};

const addRequestToQueue = (config: InternalAxiosRequestConfig): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
        requestsQueue.push({ config, resolve, reject });
    });
};

$api.interceptors.request.use((config) => {
    if (localStorage.getItem(accessTokenName)) {
        config.headers.set("Authentication", `Bearer ${localStorage.getItem(accessTokenName)}`);
    }
    // config.headers.set("X-User-Fingerprint", fingerprint);
    config.headers['X-User-Fingerprint'] = fingerprint;
    // console.log(fingerprint);
    return config;
})

$api.interceptors.response.use(
    (config) => {
        return config;
        },
    async (error) => {
        if (error.response) {
            const origReq = error.response.config;
            const err = new AxiosError(error.response.data.error);
            err.status = error.response.status;
            err.response = error.response;
            if (error.response.status === 401 && !origReq._isRetry && !isRefreshingToken) {
                isRefreshingToken = true;
                origReq._isRetry = true;
                try {
                    const authRes =
                        await axios.post<AuthResponse>(`${API_URL}/v1/auth/refresh-tokens`, {}, {withCredentials: true});
                    localStorage.setItem(accessTokenName, authRes.data.access_token);
                    processQueue();
                    return $api.request(origReq);
                } catch (authErr) {
                    console.log(authErr as Error);
                    localStorage.removeItem(accessTokenName);
                    origReq.headers.delete("Authentication");
                    await authStore.setAuth(false);
                    if (checkOptionalAuthEndpoint(origReq.url)) {
                        return $api.request(origReq);
                    }
                } finally {
                    isRefreshingToken = false;
                }
            } else if (error.response.status === 401 && !origReq._isRetry) {
                origReq._isRetry = true;
                return addRequestToQueue(origReq);
            }
            if (error.response.status >= 400) {
                throw err
            }
        } else {
            console.log(error)
        }
})

export default $api;

function checkOptionalAuthEndpoint(url: string | undefined): boolean {
    if (!url) {
        return false
    }
    if (url) {
        if (url.includes("v1/top/top-memes")
            || url.includes("v1/top/top-meme-devs")
            || url.includes("v1/memes/meme-dev/")
            || url.includes("v1/memes/battle")
            || url.match(/v1\/memes\/\d+(?!\/)/)
            || url.match(/v1\/meme-devs\/\d+(?!\/)/)
        ) {
            return true
        }
    }

    return false
}