import React, {ChangeEvent, CSSProperties, useContext, useEffect, useRef, useState} from "react";
import {CreateMemeTemplateData, ITemplate, ITemplateTitle} from "../../../../models/Templates";
import {DndProvider, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import ImageLoader from "../../../ui/ImageLoader";
import {TitleToolbar, TitleDNDInput} from "./Title/Title";
import cl from "./Constructor.module.css";
import {observer} from "mobx-react-lite";
import plus_img from "../../../images/builder/plus.svg";
import {CreateMemeData} from "../../../../models/Memes";
import {CreateMemeProps, toModelCreateMemeInfo} from "../../../../converter/Memes";
import MemeService from "../../../../services/MemeService";
import forms, {FormType} from "../../../images/forms/forms";
import {ConstructorType, DraftContext} from "../Builder";
import {CreateTemplateProps, toModelCreateMemeTemplateInfo} from "../../../../converter/Templates";
import TemplateService from "../../../../services/TemplateService";
import {useResizing} from "../../../../hooks/useResizing";
import {TouchBackend} from "react-dnd-touch-backend";
import ExportMemeBtn from "../../../ui/Buttons/ExportMemeBtn";
import {getMemeExportFileName} from "../../Memes/Memes";
import {useTranslation} from "react-i18next";
import {Context} from "../../../../index";
import { isMobile } from "react-device-detect";
import {useFetching} from "../../../../hooks/useFetching";
import {ErrorNotify} from "../../../ui/ErrorPage";
import {toast} from "react-toastify";
import {ValidationObject} from "../../../../models/Error";
import {getFontListByLang} from "../../../../views/Fonts";
import upload_img from "../../../images/builder/upload.svg";
import {sendMetric} from "../../../../pkg/Metric";

// const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const DNDBackend = isMobile ? TouchBackend : HTML5Backend;

interface ConstructorProps {
    setAuthModal(bool: boolean): void,
    constructorType: ConstructorType,
    template: ITemplate | null,
    setTemplate(t: ITemplate | null): void,
    selectedImg: File | null,
    setSelectedImg(img: File | null): void,
}

function Constructor({setAuthModal, constructorType, template, setTemplate, selectedImg, setSelectedImg} : ConstructorProps) {
    const { t } = useTranslation();
    const { authStore, localeStore } = useContext(Context);
    const { draftStore } = useContext(DraftContext);
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);

    const handleImgFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedImg(file);
            setTemplate(null);
            event.target.value = "";
            draftStore.setTags([]);
        }
    };

    function checkObjectValid() {
        if (constructorType === "Meme") {
            if (!draftStore.getIsLocal()) {
                for (let title of draftStore.titleStore.titles) {
                    if (title.text.trim().length === 0) {
                        toast.error(t("builder.meme.validation.emptyTitle"), {autoClose: 5000})
                        return false;
                    }
                }
            }
        }
        if (constructorType === "Template") {
            if (!selectedImg) {
                toast.error(t("builder.pleaseSelectNewImage"), {autoClose: 5000})
                return false
            }
        }
        return true;
    }

    function resetConstructor() {
        draftStore.reset();
        setTemplate(null);
        setSelectedImg(null);
    }

    const [create, isPending, createError] = useFetching(async () => {
        if (!authStore.isAuth) {
            setAuthModal(true);
            return;
        }
        if (!checkObjectValid()) {
            return;
        }
        if (constructorType === "Meme") {
            const props: CreateMemeProps = {
                withTemplate: saveAsTemplate,
                templateId: template ? template.id : undefined,
                langCode: localeStore.getMemeLanguage(),
                regCode: localeStore.getRegion(),
            }
            const createMemeInfo = toModelCreateMemeInfo(draftStore, props);
            console.log(createMemeInfo);
            const data: CreateMemeData = {
                CreateMemeInfo: JSON.stringify(createMemeInfo),
                Image: selectedImg ? selectedImg : undefined
            }
            const response = await MemeService.createMeme(data);
            console.log(response);
            toast.success(t("builder.meme.successCreation"), {autoClose: 3000});
            resetConstructor();
            sendMetric("goal", "created_meme");
            return;
        }
        if (constructorType === "Template" && selectedImg) {
            const props: CreateTemplateProps = {
                langCode: localeStore.getMemeLanguage(),
                regCode: localeStore.getRegion(),
            }
            const createTemplateInfo = toModelCreateMemeTemplateInfo(draftStore, props);
            console.log(createTemplateInfo);
            const data: CreateMemeTemplateData = {
                CreateMemeTemplateInfo: JSON.stringify(createTemplateInfo),
                Image: selectedImg,
            }
            const templateId = await TemplateService.createTemplate(data);
            console.log(templateId);
            toast.success(t("builder.tmpl.successCreation"), {autoClose: 3000});
            resetConstructor();
            sendMetric("goal", "created_template");
            return;
        }
    });

    useEffect(() => {
        if (createError) {
            const obj: ValidationObject = constructorType === "Meme" ? "meme" : "template";
            const props = {allMessages: true, validationObj: obj}
            ErrorNotify({err: createError, validationErrProps: props});
        }
    }, [createError]);

    return (
        <>
            {constructorType === "Meme" &&
                <>
                    <div className={cl.TopBar}>
                        <div className={cl.MemePanelBtnContainer}>
                            <ExportMemeBtn
                                meme={{id: 0, url: draftStore.getImgSrc(), titles: draftStore.titleStore.getTitles()}}
                                fileName={getMemeExportFileName(true)}
                                specifyMetricName={"download_meme_from_builder"}/>
                        </div>
                        <SelectImgBtn setImage={handleImgFileChange}
                                      containerStyle={{width: "auto"}}/>
                        <div style={{width: "1.5em"}}></div>
                    </div>
                    { selectedImg && <FormSelector/> }
                </>
            }
            {constructorType === "Template" &&
                <>
                    <div className={cl.TopBar}>
                        <div style={{width: "1.5em"}}></div>
                        <SelectImgBtn setImage={handleImgFileChange}
                                      containerStyle={{width: "auto"}}/>
                        <div style={{width: "1.5em"}}></div>
                    </div>
                    <FormSelector/>
                </>
            }
            <div style={{height: "0.5em"}}></div>
            {(template === null && selectedImg === null) || (constructorType === "Template" && selectedImg === null)
                ? <div className={cl.SelectImgAttention}>{t("builder.pleaseSelectNewImage")}</div>
                : <DndProvider backend={DNDBackend}>
                    <MemeDraft constructorType={constructorType}
                             template={template}
                             newImg={selectedImg}/>
                  </DndProvider>
            }
            <ListTitleToolbar constructorType={constructorType}/>
            <CreationSettings constructorType={constructorType}
                              templateTags={template ? template.tags : []}
                              newImg={!!selectedImg}
                              saveAsTemplate={saveAsTemplate}
                              setSaveAsTemplate={setSaveAsTemplate}/>
            <CreateBtn constructorType={constructorType}
                       handleCreate={() => create({})}
                       isPending={isPending}/>
        </>
    );
}

export default observer(Constructor);

interface SelectImgBtnProps {
    setImage(event: ChangeEvent<HTMLInputElement>): void,
    containerStyle?: CSSProperties,
}

function SelectImgBtn({setImage, containerStyle}: SelectImgBtnProps) {
    const { t } = useTranslation();
    return (
        <div className={cl.NewImage}
             style={containerStyle}>
            <input className={cl.ImageInput}
                   type="file"
                   name="image_input"
                   id="image_input"
                   accept="image/png, image/jpeg, image/webp"
                   onChange={setImage}/>
            <label htmlFor="image_input"
                   className={cl.ImageInputLabel}>
                <img className={cl.ImageInputPicture}
                     src={upload_img}
                     alt={"upload_img"}/>
                <div>{t("builder.selectImage")}</div>
            </label>
        </div>
    );
}

interface CreateBtnProps {
    constructorType: ConstructorType,
    // isObjectValid: boolean,

    handleCreate(): void,

    isPending: boolean,
}

function CreateBtn({constructorType, handleCreate, isPending} : CreateBtnProps) {
    const { t } = useTranslation();
    function getBtnText() {
        if (isPending) {
            return t("ui.pending");
        }
        if (constructorType === "Meme") {
            return t("builder.meme.upload");
        }
        if (constructorType === "Template") {
            return t("builder.tmpl.upload");
        }
    }

    return (
        // <button className={(isObjectValid && !isPending)
        //                     ? `${cl.Button} ${cl.UploadMeme}`
        //                     : `${cl.Button} ${cl.UploadMemeUnavailable}`}
        //         onClick={(isObjectValid && !isPending)
        //                     ? handleCreate
        //                     : () => {}}>
        <button className={!isPending
                            ? `${cl.Button} ${cl.UploadMeme}`
                            : `${cl.Button} ${cl.UploadMemeUnavailable}`}
                onClick={!isPending
                            ? handleCreate
                            : () => {}}>
            <div style={{fontSize: "var(--small-font-size)"}}>
                {getBtnText()}
            </div>
        </button>
    );
}

const FormSelector = observer(() => {
    const { t } = useTranslation();
    const { draftStore } = useContext(DraftContext);
    useEffect(() => {
        draftStore.setForm("x_x");
    }, []);
    return (
        <div style={{display: "none"}}>
            <div className={cl.FormIconListTile}>{`${t("builder.selectForm")}:`}</div>
            <div className={cl.FormIconListContainer}>
            {forms.slice(0, forms.length-1).map((form) =>
                <div className={cl.FormIconContainer}
                     key={form.formType}
                     onClick={() => {draftStore.setForm(form.formType)}}
                     style={{backgroundColor: draftStore.form === form.formType ? "var(--main-color)" : "white"}}>
                    { form.formType === "x_x"
                        ? forms[forms.length-1].svg({size: "70%", color: draftStore.form === form.formType ? "white" : "var(--std-text-color)"})
                        : form.svg({size: "70%", color: draftStore.form === form.formType ? "white" : "var(--std-text-color)"}) }
                </div>
            )}
            </div>
        </div>
    );
})

function calcPercentageOffset (
    clientOffset: {x: number, y: number},
    parentSize: {width: number, height: number},
){
    const oX = (clientOffset.x / parentSize.width);
    const oY = (clientOffset.y / parentSize.height);
    return { oX, oY };
}

interface MemeDraftProps {
    constructorType: ConstructorType,
    template: ITemplate | null,
    newImg: File | null,
}

const MemeDraft = observer(({constructorType, template, newImg} : MemeDraftProps) => {
    const { localeStore } = useContext(Context);
    const { draftStore } = useContext(DraftContext);
    const titleStore = draftStore.titleStore;
    const imgContainer = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const [lastMemeLang, setLastMemeLang] = useState(localeStore.getMemeLanguage());
    function getPlaceHolder() {
        if (constructorType === "Template") {
            return t("builder.tmpl.titlePlaceholder", {lng: localeStore.getMemeLanguage()});
        }
        if (constructorType === "Meme") {
            return  t("builder.meme.editableTitlePlaceholder", {lng: localeStore.getMemeLanguage()});
        }
        return ""
    }
    function getContainerSize(imgContainer: HTMLDivElement) {
        const width = imgContainer.clientWidth;
        const height = imgContainer.clientHeight;
        return {width: width, height: height};
    }

    useEffect(() => {
        if (imgContainer.current && (localeStore.getMemeLanguage() !== lastMemeLang)) {
            titleStore.setAllTitles(
                titleStore.titles,
                getContainerSize(imgContainer.current),
                getPlaceHolder(),
            )
            setLastMemeLang(localeStore.getMemeLanguage());
        }
    }, [localeStore.memeLanguage]);

    useEffect(() => {
        if (newImg) {
            draftStore.setImgSrc(URL.createObjectURL(newImg));
            // draftStore.setTags([]);
        }
    }, [newImg]);

    // if user changes window size
    // or if user loads new image
    // or if img_ratio is incorrect
    // it is necessary to recalculate container size and titles positions
    function handleResize(entries: ResizeObserverEntry[]) {
        const {width, height} = entries[0].contentRect;
        if (width > 0 && height > 0) {
            draftStore.setImgRatio(width / height);
            titleStore.setAllTitles(
                constructorType === "Template" ? titleStore.titles.map((title) => ({
                    ...title,
                    text: ""
                })) : titleStore.titles,
                {width: width, height: height},
                getPlaceHolder(),
            )
        }
    }
    useResizing(imgContainer, handleResize);

    useEffect(() => {
        if (imgContainer.current) {
            const containerSize = getContainerSize(imgContainer.current);
            if (template) {
                draftStore.setImgSrc(template.img_url);
                draftStore.setImgRatio(template.img_ratio);
                // draftStore.setTags(template.tags);
                draftStore.setForm(template.form as FormType);
                draftStore.setIsLocal(false);
                // document.fonts.ready.then(function () {
                //     // Здесь выполняйте расчеты размеров текста
                    titleStore.setAllTitles(
                        template.titles.map((title) => ({text: "", ...title})),
                        containerSize,
                        getPlaceHolder(),
                    );
                // });
                // console.log("img_ratio", template.img_ratio)
            } else {
                // document.fonts.ready.then(function() {
                //     // Здесь выполняйте расчеты размеров текста
                    titleStore.setAllTitles(
                        constructorType === "Template" ? titleStore.titles.map((title) => ({...title, text: ""})) : titleStore.titles,
                        containerSize,
                        getPlaceHolder(),
                    );
                // });
            }
        }
    }, [template?.id, constructorType]);


    // DnD hook
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'textarea',
        drop: ({index}: {index: number}, monitor) => {
            const delta = monitor.getDifferenceFromInitialOffset() || { x: 0, y: 0 };
            const container = imgContainer.current;
            if (container) {
                const oXY = calcPercentageOffset(
                    {x: delta.x, y: delta.y},
                    {width: container.clientWidth, height: container.clientHeight},
                )
                const title = titleStore.titles[index];
                titleStore.setPosition(index, title.x_ratio + oXY.oX, title.y_ratio + oXY.oY);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div ref={drop}>
            <div ref={imgContainer}
                 className={cl.MemeContainer}>
                <ImageLoader key={draftStore.imgSrc} // to full rerender when img src changes
                             url={draftStore.imgSrc}
                             imgStyle={{width: "100%"}}
                             animStyle={{width: "100%", paddingBottom: `${100/(template?.img_ratio || 1)}%`}}
                             alt={"image"}
                             ImgAddon={<ListTitleDNDInput constructorType={constructorType}/>}
                />
            </div>
        </div>
    );
})

interface ListTitleDNDInputProps {
    constructorType: ConstructorType,
    titles?: ITemplateTitle[],
}

const ListTitleDNDInput
    = observer(({constructorType}:ListTitleDNDInputProps) => {
    const titleStore = useContext(DraftContext).draftStore.titleStore;
    return (
        <>
            {titleStore.titles.map((title, index) =>
                <TitleDNDInput index={index}
                               constructorType={constructorType}
                               key={titleStore.titleIds[index]} // maybe need to get title ids for key from server
                />)}
        </>
    );
})

interface ListTitleToolbarProps {
    constructorType: ConstructorType,
}

const ListTitleToolbar
    = observer(({constructorType} : ListTitleToolbarProps) => {
        const { draftStore } = useContext(DraftContext);
        const titleStore = draftStore.titleStore;
        const { t } = useTranslation();
        const { localeStore } = useContext(Context);
        const fonts = getFontListByLang(localeStore.getMemeLanguage());

        function handleAppendTitle() {
            let lastIndex = titleStore.titles.length - 1;
            if (lastIndex >= 0) {
                const newTitle = titleStore.cloneTitle(lastIndex);
                newTitle.text = "";
                newTitle.x_ratio = 0.5;
                newTitle.y_ratio = 0.5;
                titleStore.append(newTitle);
            } else {
                titleStore.appendDefault(fonts[0]);
                draftStore.setIsLocal(false);
            }
        }

        return (
            <>
                {titleStore.titles.map((title, index) =>
                    <TitleToolbar index={index}
                                  constructorType={constructorType}
                                  key={titleStore.titleIds[index]} // maybe need to get title ids for key from server
                    />)
                }
                {titleStore.titles.length < 10 &&
                    <button className={cl.Button + " " + cl.AppendTitle}
                            onClick={handleAppendTitle}>
                        <img className={cl.AppendTitleImg}
                             src={plus_img}
                             alt={"plus_img"}/>
                        <div>{t("builder.appendTitle")}</div>
                    </button>}
            </>
        );
    })

interface CreationSettingsProps {
    constructorType: ConstructorType,
    templateTags: string[],
    newImg: boolean,
    saveAsTemplate: boolean,
    setSaveAsTemplate(b: boolean): void,
}

const CreationSettings = observer((props: CreationSettingsProps) => {
    const { t } = useTranslation();
    const { draftStore } = useContext(DraftContext);
    const titlesLen = draftStore.titleStore.titles.length;

    function setUntranslatableHandler() {
        if (titlesLen === 0) {
            draftStore.setIsLocal(true);
        } else {
            draftStore.setIsLocal(!draftStore.isLocal);
        }
    }

    function setSaveAsTemplateHandler() {
        if (titlesLen === 0) {
            props.setSaveAsTemplate(false);
        } else {
            props.setSaveAsTemplate(!props.saveAsTemplate);
        }
    }

    useEffect(() => {
        if (titlesLen === 0) {
            draftStore.setIsLocal(true);
            props.setSaveAsTemplate(false);
        }
    }, [titlesLen]);

    // const saveAsTmplAddition = props.saveAsTemplate ? (". " + t("builder.tmpl.enterTags")) : "";
    // const isTagsInputActive = props.constructorType === "Template" || (props.saveAsTemplate && props.newImg);

    function handleTagsChange(e: ChangeEvent<HTMLTextAreaElement>) {
        const input = e.target.value;
        // check to: (dont put space as first char) || (dont put more than 1 empty tags)
        if (input === " " || (input.charAt(input.length - 1) === " " && input.charAt(input.length - 2) === "#")) {
            return
        }

        let inputTags = input.split(" ");
        const inputTagsCharCount = inputTags.reduce((total, tag) => total + tag.length, 0);
        const storedTagsCharCount = draftStore.getTags().reduce((total, tag) => total + tag.length, 0);

        if (inputTagsCharCount > storedTagsCharCount || inputTags.length > draftStore.getTags().length) {
            draftStore.setTags(inputTags
                .filter((value, index) => {
                    return !(value === "" && index !== inputTags.length - 1) //drop all empty tags, except last empty tag
                })
                .map((tag) => {
                    return `#${tag.replace(/#/g, "")}` //drop all '#' in every tag and put '#' to start of tags
                })
            )
        } else {
            draftStore.setTags(input
                .trim() //if user deletes '#', we need to trim space of input string
                .split(" ")
                .filter((value, index) => {
                    return !(value === "" && index !== inputTags.length - 1) //drop all empty tags, except last empty tag
                })
                .map((tag) => {
                return `#${tag.replace(/#/g, "")}` //drop all '#' in every tag and put '#' to start of tags
            }))
        }
    }

    return (
        <>
            {props.constructorType === "Meme" &&
                <>
                    <div className={cl.CheckBoxContainer}>
                        <input type={"checkbox"}
                               id={"untranslatable"}
                               name={"untranslatable"}
                               checked={draftStore.isLocal}
                               onChange={setUntranslatableHandler}/>
                        <label htmlFor={"untranslatable"}>{t("builder.meme.untranslatable")}</label>
                    </div>
                    {props.newImg &&
                        <div className={cl.CheckBoxContainer}>
                            <input type={"checkbox"}
                                   id={"saveAsTemplate"}
                                   name={"saveAsTemplate"}
                                   checked={props.saveAsTemplate}
                                   onChange={setSaveAsTemplateHandler}/>
                            <label htmlFor={"saveAsTemplate"}>
                                {t("builder.meme.saveAsTmpl")}
                            </label>
                        </div>
                    }
                </>
            }
            <div className={cl.EnterTagsAttention}>{t("builder.enterTags")}</div>
            <div className={cl.TagsInputWrapper}>
                <textarea className={cl.TagsInput}
                          id={`tagsInput`}
                          value={draftStore.tags.join(" ")}
                          placeholder={t("builder.tagsPlaceholder").split(" ").map((str) => `#${str}`).join(" ")} // add '#' for each word in placeholder
                          onChange={handleTagsChange}
                />
            </div>
        </>
    );
})