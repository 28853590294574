import {ReportMemeView, TitleReportView} from "../views/ReportMeme";
import {ReportMeme, TitleReport} from "../models/MemeBattle";

export function toModelReportMeme(report: ReportMemeView): ReportMeme {
    const data: ReportMeme = {
        meme_id: report.meme_id,
        category_id: 0,
        lang: report.lang,
        reg: report.reg,
    }
    switch (report.category) {
        case "other": data.category_id = 1; break;
        case "spam": data.category_id = 2; break;
        case "violenceOrHostility": data.category_id = 3; break;
        case "adultContent": data.category_id = 4; break;
    }

    return data
}

export function toModelTitleReport(report: TitleReportView): TitleReport {
    const data: TitleReport = {
        meme_id: report.meme_id,
        category_id: 0,
        lang: report.lang,
        reg: report.reg,
    }
    switch (report.category) {
        case "translation": data.category_id = 1; break;
        case "position": data.category_id = 2; break;
    }

    return data
}