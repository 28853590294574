import {MutableRefObject, useEffect} from "react";

export function useResizing(ref: MutableRefObject<HTMLDivElement | null>, callback: (entries: ResizeObserverEntry[]) => void) {
    useEffect(() => {
        let refElem = ref.current;

        const resizeObserver = new ResizeObserver((entries) => {
            // Вызываем requestAnimationFrame для отложенного выполнения
            requestAnimationFrame(() => {
                // Обработка изменений размера
                callback(entries);
            });
        });
        if (refElem) {
            resizeObserver.observe(refElem);
        }

        return () => {
            if (refElem) {
                resizeObserver.unobserve(refElem);
            }
            resizeObserver.disconnect();
        };
    }, []);
}