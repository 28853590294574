import $api from "../http";
import {BlockedTagsInfo} from "../models/Tag";

export default class TagService {
    static async blockTag(tag: string) {
        return await $api.post("/v1/tags/black-list", {tag: tag});
    }

    static async unblockTag(tag: string) {
        return await $api.delete("/v1/tags/black-list", {data: {tag: tag}});
    }

    static async unblockAllTags() {
        return await $api.delete("/v1/tags/black-list");
    }

    static async getBlockedTags() {
        const response = await $api.get<BlockedTagsInfo>("/v1/tags/black-list");
        return response.data;
    }
}