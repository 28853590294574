export function capitalizeFirstLetter(str: string) {
    if (str.length === 0) {
        return str
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function djbHash32pos(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) + hash + char; // hash * 33 + char
    }
    return hash >>> 0; // Convert to unsigned 32-bit integer
}

export function djbHash(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) + hash + char; // hash * 33 + char
    }
    return hash;
}

export const getURLWithQueryParams = (
    baseUrl: string,
    params: Record<string, any>
) => {
    const query = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&")

    return `${baseUrl}?${query}`
}

export const generateBase64UrlEncode = () => {
    const randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    return btoa(String.fromCharCode(...Array.from(randomBytes)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, ''); // Приведение к URL-совместимому формату
};

// Преобразование code_verifier в code_challenge с использованием SHA-256
export const generateCodeChallenge = async (codeVerifier: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...Array.from(new Uint8Array(hash))))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, ''); // Приведение к URL-совместимому формату
};