import {config} from "../config";
import {logEvent} from "firebase/analytics";
import {analytics} from "../firebaseConfig";

export type MetricType = "url" | "goal"

export function sendMetric(mType: MetricType, value: string) {
    if (mType === "url") {
        // @ts-ignore
        window.ym(config.YMid, 'hit', value);
        //logEvent(analytics, value);
        return;
    }
    if (mType === "goal") {
        // @ts-ignore
        window.ym(config.YMid,'reachGoal',value);
        // @ts-ignore
        window._tmr.push({ type: 'reachGoal', id: config.VKADSid, goal: value});
        logEvent(analytics, value);
        return;
    }
}