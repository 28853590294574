import share_img from "../../images/meme/share.png";
import cl from "./Btn.module.css"
import React from "react";
import copy from 'clipboard-copy';
import i18n from "../../../ui-translations/i18n";
import {toast} from "react-toastify";
import {sendMetric} from "../../../pkg/Metric";

export interface ShareBtnProps {
    link: string,
    callback?(): void | Promise<void>
}

export default function ShareBtn(props: ShareBtnProps) {
    const handleCopyClick = async () => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(props.link);
            } else {
                await copy(props.link); //fallback option, sometimes it works slowly
            }
            toast.success(i18n.t("ui.linkCopied"), {autoClose: 1000});
            sendMetric("goal", "share_meme");
        } catch (e) {
            console.log(e as Error);
            toast.error(i18n.t("ui.defErrMsg"));
        }

        if (props.callback) {
            try {
                await props.callback()
            } catch (e) {
                console.log(e as Error);
            }
        }
    };
    return (
        <div className={cl.MemePanelBtn}>
            <img className={cl.MemePanelBtnImg}
                 onClick={handleCopyClick}
                 src={share_img} alt="share"/>
        </div>
    );
}