export const translationsHI = {
    common: {
        and: "और",
        or: "या",
    },
    ui: {
        defErrMsg: "कुछ गलत हो गया :(",
        notFoundErrMsg: "404. पृष्ठ नहीं मिला",
        loading: "लोड हो रहा है...",
        imageExportMsg: "छवि लोड होने वाली है",
        linkCopied: "लिंक क्लिपबोर्ड पर कॉपी किया गया",
        pending: "लंबित...",
        goToWebsite: "वेबसाइट पर जाएं",
        localMeme: "स्थानीय मीम",
        delete: "हटाएं",
        submit: "पुष्टि करें",
        cancel: "रद्द करें",
        tag: {
            showTags: "टैग दिखाएं",
            hideTags: "टैग छुपाएं",
            clickToBlock: "ब्लैकलिस्ट में टैग जोड़ने के लिए क्लिक करें",
            clickToUnblock: "ब्लैकलिस्ट से किसी टैग को अनब्लॉक करने के लिए क्लिक करें",
            tagBlocked: "टैग अवरुद्ध है। इस टैग वाले मेम्स को संग्रह से बाहर रखा जाएगा",
            tagUnblocked: "टैग अनब्लॉक किया गया है"
        },
        memeDev: {
            block: "ब्लॉक",
            unblock: "अनब्लॉक",
            memeDevBlocked: "उपयोगकर्ता को ब्लॉक कर दिया गया है। उसकी सामग्री को संग्रह से बाहर कर दिया जाएगा",
            memeDevUnblocked: "उपयोगकर्ता अनब्लॉक है",
        },
        betaInfo: "पप्रिय उपयोगकर्ता,\nबीटा परीक्षण में भाग लेने के लिए आपका बहुत-बहुत धन्यवाद! यदि आपको कोई बग मिलता है या सुधार के लिए आपके पास विचार हैं, तो हमें ईमेल द्वारा लिखें:",
        mobileMarketNoticeTitle: "हमारा एप्लिकेशन दुकानों में उपलब्ध है:",
        cookieAttention: {
            title: "हम कुकीज़ का उपयोग करते हैं",
            info: "हमारी साइट का उपयोग करके आप कुकीज़ के उपयोग के लिए सहमत होते हैं। हम प्राधिकरण के लिए कुकीज़ का उपयोग करते हैं। इससे हमें यह सुनिश्चित करने में मदद मिलती है कि वेबसाइट का उपयोग करना आसान है।",
            understood: "मैं समझता हूँ",
        },
        featureTemporarilyDisabled: "यह सुविधा अस्थायी रूप से अक्षम है, हम इस पर काम कर रहे हैं",
        memeRatingTitleGlobal: "अनुमानित वैश्विक रेटिंग मूल्य. जीत, हार और कुल वोटों की संख्या पर निर्भर करता है",
        memeRatingTitleLocal: "स्थानीय रेटिंग मान की गणना की गई. जीत, हार और कुल वोटों की संख्या पर निर्भर करता है",
        memeWins: "जीत की संख्या",
        memeLosts: "नुकसान की संख्या",
    },
    apiError: {
        _400: {
            invalidTemplateForm: "अमान्य टेम्पलेट फ़ॉर्म",
            invalidBirthDate: "अमान्य जन्म तिथि",
            imageMissing: "छवि गायब",
            emailIsNotValid: "ईमेल मान्य नहीं है, या उसी ईमेल वाला उपयोगकर्ता पहले से मौजूद है।",
        },
        _401: {
            wrongLoginPwd: "गलत लॉगिन और/या पासवर्ड",
            wrongPwd: "गलत पासवर्ड",
        },
        _403: {
            accountNotVerified: "आपका खाता सत्यापित नहीं है",
            accessDenied: "पहुँच निषेधित है",
        },
        _404: {
            notFound: "अनुरोधित संसाधन नहीं मिला",
            memeNotFound: "मीम नहीं मिला",
            userNotFound: "उपयोगकर्ता नहीं मिला",
            templateNotFound: "टेम्पलेट नहीं मिला",
            langNotSupported: "भाषा समर्थित नहीं है",
            regNotSupported: "क्षेत्र मौजूद नहीं है या समर्थित नहीं है",
            activationLink: "खाता सक्रियण लिंक अमान्य है या समय समाप्त हो गया है",
            pwdRestoreLink: "पासवर्ड पुनर्प्राप्ति लिंक गलत है या समाप्त हो गया है",
            unknownReportCategory: "अज्ञात रिपोर्ट श्रेणी",
        },
        _409: {
            cantJudge: "इस मीम के जोड़े को न्याय के लिए उपलब्ध नहीं है",
            emailExists: "दिए गए ईमेल के साथ उपयोगकर्ता पहले से मौजूद है",
            nicknameExists: "दिए गए उपनाम के साथ उपयोगकर्ता पहले से मौजूद है",
            alreadyReported: "आपने पहले ही इस पोस्ट की रिपोर्ट की है",
        },
        _413: {
            imgTooLarge: "छवि फ़ाइल का आकार 10 एमबी से अधिक नहीं होना चाहिए",
            stringBodyTooLarge: "पाठ डेटा का आकार 10 KB से अधिक नहीं होना चाहिए",
        },
        _422: {
            imgFileType: "छवि फ़ाइल प्रारूप समर्थित नहीं है। jpg/jpeg या png का चयन करें",
            imgRatio: "छवि बहुत खिची हुई है, आस्था अनुपात 10 के 1 से अधिक नहीं होना चाहिए",
            lang: "चयनित भाषा समर्थित नहीं है",
            reg: "चयनित क्षेत्र समर्थित नहीं है",
            userCreation: {
                birthDate: "आपकी आयु 12 से 100 वर्ष के बीच होनी चाहिए",
                email: "ईमेल का मान्य प्रारूप होना चाहिए",
                firstName: "पहले नाम की लंबाई 100 अक्षरों से अधिक नहीं होनी चाहिए",
                lastName: "अंतिम नाम की लंबाई 100 अक्षरों से अधिक नहीं होनी चाहिए",
                nickname: "उपनाम में केवल लैटिन अक्षर और अंक होने चाहिए, केवल अंकों से नहीं बनना चाहिए",
                password: "पासवर्ड की लंबाई 6 से 20 अक्षरों के बीच होनी चाहिए",
            },
            memeOrTemplateCreation: {
                titles: {
                    memeTitles: "शीर्षक के बिना आप केवल अद्भुत मीम बना सकते हैं",
                    tmplTitles: "आप शिलालेखों के बिना कोई टेम्पलेट नहीं बना सकते",
                    font_size: "फ़ॉन्ट आकार बहुत बड़ा या बहुत छोटा है",
                    text: "शीर्षक खाली नहीं होना चाहिए और शीर्षक 2000 अक्षरों से अधिक नहीं होना चाहिए",
                    angle: "अमान्य शीर्षक कोण",
                    textColor: "अमान्य शीर्षक रंग",
                    xRatio: "शीर्षक छवि की लंबवत सीमाओं से बाहर निकलता है",
                    yRatio: "शीर्षक छवि की क्षैतिज सीमाओं से बाहर निकलता है",
                },
                templateAddon: {
                    form: "अमान्य मीम या टेम्पलेट फ़ॉर्म",
                    tags: "टैग खाली नहीं होने चाहिए और डुप्लिकेट नहीं होने चाहिए. कुल वर्णों की संख्या 100 से अधिक नहीं होनी चाहिए",
                },
            }
        },
        _429: {
            creationLimit: "पोस्ट सीमा पार की गई। रोजाना 10 मीम प्रकाशन और 10 टेम्पलेट उपलब्ध हैं",
            emailSendingLimit: "ईमेल भेजने की सीमा पार की गई। प्रति मिनट 1 पत्र और प्रति सप्ताह 10 पत्र उपलब्ध हैं",
        },
    },
    navbar: {
        hotMemes: "हॉट मीम्स",
        topMemeDevs: "शीर्ष डेवलपर्स",
        battleField: "युद्ध",
        builder: "निर्माता",

        localeSettingsTitle: "मेम भाषा",
        additional: "अतिरिक्त",
        support: "समर्थन से संपर्क करें",
    },
    report: {
        popUpTitle: "शिकायत करें",
        modalTitle: "शिकायत का कारण चुनें",
        spam: "स्पैम",
        violenceOrHostility: "हिंसा या दुश्मनी",
        adultContent: "वयस्क सामग्री",
        other: "अन्य",
        translation: "गलत शीर्षक अनुवाद",
        position: "गलत शीर्षक स्थिति",
        successSending: "शिकायत भेजी गई",
    },
    authForm: {
        login: {
            title: "लॉगिन",
            email: "ईमेल",
            password: "पासवर्ड",
            forgotPwd: "क्या आप पासवर्ड भूल गए?",
            restorePwd: "पासवर्ड बहाल करें",
        },
        signUp: {
            title: "साइन अप",
            nickname: "निकनेम (लैटिन अक्षर और अंक)",
            firstname: "पहला नाम",
            lastname: "अंतिम नाम",
            birthDate: "जन्म तिथि",
            birthDateTooltip: "यह हमें आपको सर्वोत्तम मीम्स की सिफारिश करने में मदद करेगा",
            enterYourAge: "कृपया अपनी आयु बताएं",
            yourAge: "आपकी उम्र",
            memesLang: "मीम्स भाषा",
            region: "आपका क्षेत्र",
            email: "ईमेल",
            password: "पासवर्ड",
            submitPassword: "पासवर्ड प्रस्तुत करें",
            summoner: "आमंत्रक id या निकनेम",
            summonerTooltip: "यदि आपको किसी मित्र ने आमंत्रित किया है, तो उसका उपनाम या आईडी दर्ज करना न भूलें",
            agreement: {
                agree: "ममैं",
                privacy: "गोपनीयता नीति",
                and: "और",
                terms: "उपयोग की शर्तों",
                ending: " से सहमत हूँ"
            },
            agreementModal: {
                title: "उपयोगकर्ता का समझौतां",
                byStaying: "साइट पर बने रहकर, आप",
                privacy: "गोपनीयता नीति",
                and: "और",
                terms: "उपयोग की शर्तों",
                ending: " को स्वीकार करते हैं",
                acceptBtn: "स्वीकार किया"
            },
            successSignUp: "निर्दिष्ट पते पर आपके खाते को सत्यापित करने के लिए एक लिंक सहित ईमेल भेजा गया था",
            error: {
                pwdDontMatch: "दर्ज किए गए पासवर्ड मेल नहीं खाते",
                age: "पंजीकरण के लिए आपकी आयु कम से कम 12 वर्ष और 100 वर्ष से अधिक नहीं होनी चाहिए",
            }
        },
    },
    profile: {
        premium: {
            premiumAccount: "प्रीमियम खाता",
            daysLeft: "प्रीमियम स्थिति समाप्त होने तक दिन: ",
            monthsLeft: "प्रीमियम स्थिति समाप्त होने तक के महीने: "
        },
        popUp: {
            myProfile: "मेरी प्रोफाइल",
            settings: "सेटिंग्स",
            logout: "लॉग आउट",
        },
        public: {
            wins: "जीत",
            defeats: "हार",
            judged: "मूल्यांकित",
            position: "स्थिति",
            memesCount: "मीम्स",
            rating: "रेटिंग",
        },
        level: {
            l1: "जूनियर मीम-डेवलपर",
            l2: "मध्यम मीम-डेवलपर",
            l3: "वरिष्ठ मीम-डेवलपर",
            l4: "मीम-गुरु",
            l5: "लगभग एक मीम-भगवान",
            l6: "मीम-भगवान",
        },
        memes: {
            title: "लेखक मीम्स",
            sort: {
                firstFresh: "पहले ताज़ा",
                firstOld: "पहले पुराने",
                ratingDown: "रेटिंग नीचे",
                ratingUp: "रेटिंग ऊपर",
            },
            areYouSureDeleteMeme: "क्या आप मेम हटाना चाहते हैं?",
            successDeletion: "मीम हटा दिया गया",
        },
        badges: {
            percentOfPlayersEarned: "%v खिलाड़ियों के पास यह उपलब्धि है",
            nextLevel: "अगला स्तर",
        },
        settings: {
            title: "प्रोफ़ाइल सेटिंग्स",
            nicknameDesc: "नाम बदलें",
            selectAvatar: "एक अवतार चुनें",
            avatarUpdated: "अवतार सफलतापूर्वक अपडेट किया गया",
        },
        advancedSettings: {
            title: "सेटिंग्स",
            locale: {
                title: "स्थान",
                memesLangName: "भाषा",
                memesLangDesc: "मीम्स भाषा बदलें",
                regName: "क्षेत्र",
                regDesc: "भौगोलिक क्षेत्र बदलें",
            },
            security: {
                title: "सुरक्षा",
                emailName: "ईमेल",
                emailDesc: "आपका ईमेल",
                pwdName: "पासवर्ड",
                changePwd: "पासवर्ड बदलें",
                fullLogoutName: "लॉगआउट",
                fullLogoutDesc: "सभी उपकरणों से लॉगआउट",
                enterCurrentPwd: "वर्तमान पासवर्ड दर्ज करें",
                enterNewPwd: "नया पारण शब्द भरे",
                pwdUpdateOrRestoreSuccess: "आपका पासवर्ड बदल दिया गया है",
                enterEmailToRestorePwd: "वह ईमेल दर्ज करें जिस पर आपका खाता पंजीकृत है",
                sendRestoreLink: "पुनर्प्राप्ति लिंक भेजें",
                emailToRestorePwdSent: "निर्दिष्ट ईमेल पर एक पुनर्प्राप्ति लिंक भेजा गया है।",
            },
            blackList: {
                title: "ब्लैकलिस्ट",
                clearBlackList: "ब्लैकलिस्ट साफ़ करें",
                clearBlockedTagsSubmitDesc: "क्या आप वाकई अवरुद्ध टैग की सूची साफ़ करना चाहते हैं?",
                clearBlockedMemeDevsSubmitDesc: "क्या आप वाकई अवरुद्ध उपयोगकर्ताओं की सूची साफ़ करना चाहते हैं?",
                blockedTagsName: "अवरुद्ध टैग",
                noBlockedTags: "कोई अवरुद्ध टैग नहीं",
                blockedMemeDevsName: "अवरुद्ध मेम डेवलपर्स",
                noBlockedMemeDevs: "कोई अवरुद्ध मेम डेवलपर्स नहीं",
            },
            additional: {
                title: "अतिरिक्त",
                deleteAccount: "खाता हटाएं",
                restoreAccount: "खाता पुनर्स्थापित करें",
                deleteAccountAttentionTitle: "क्या आप अपना खाता हटाना चाहते हैं?",
                deleteAccountAttentionDesc: "आप 14 दिनों के भीतर खाता हटाना रद्द कर सकते हैं। इस अवधि के बाद आपका खाता हमेशा के लिए हटा दिया जाएगा. आपका व्यक्तिगत डेटा हटा दिया जाएगा. आपके द्वारा बनाए गए मीम गुमनाम हो जाएंगे.",
                acceptAccountDeletion: "हटाएं",
                accountDeletionMsg: "आपका खाता %v दिनों में हटा दिया जाएगा",
                accountDeletionCanceledMsg: "आपने खाता हटाना रद्द कर दिया है",
            },
        },
        verification: {
            success: "आपका खाता सत्यापित हो गया है",
            bannerMsg: "आपका खाता सत्यापित नहीं है",
            bannerBtn: "सेटिंग्स पर जाएं",
            settings: {
                title: "खाता सत्यापन",
                accountNotVerifiedMsg: "आपका खाता सत्यापित नहीं किया गया है. कृपया अपना ईमेल बताएं और पुष्टि करें",
                sendEmail: "ईमेल की पुष्टि करें",
                emailIsSent: "आपके ईमेल पर खाते की पुष्टि के लिए एक ईमेल भेजा गया है",
                nextEmailRemaining: "ईमेल पुनः भेजने के लिए, कृपया प्रतीक्षा करें। बचे हुए (सेकंड):",
            }
        },
    },
    topMemes: {
        title: "आपके लिए चुने गए हॉट मीम्स",
        loginToSeeMore: "अधिक मीम्स देखने के लिए लॉगिन करें",
    },
    duncanChest: {
        title: "इस सप्ताह के लिए चुने हुए मीम",
    },
    battleField: {
        title: "विजेता का चयन करें",
        left: "बाएँ",
        right: "दाएँ",
        fullScreen: "पूर्ण स्क्रीन मोड में जाएं",
        collapse: "संक्षेपित",
        allBattlesJudged: "आपने अब तक उपलब्ध सभी युद्धों का न्याय किया है।",
        signUpInfo: "विभिन्न उपकरणों पर रजिस्टर और मीम्स को दोहराया नहीं जाएगा",
    },
    builder: {
        searchTmpl: {
            filterByForm: "फ़ॉर्म द्वारा फ़िल्टर करें",
            searchByTags: "टैग द्वारा खोजें",
            noSearchResults: "खोज ने कोई परिणाम नहीं दिया है",
            expandPreviewList: "पूर्ण स्क्रीन तक विस्तृत करें",
            collapsePreviewList: "संक्षेपित",
        },
        meme: {
            originalLang: "मूल भाषा",
            create: "मीम बनाएं",
            creationDesc: "नया मीम बनाने का मोड",
            editableTitlePlaceholder: "टेक्स्ट दर्ज करें...",
            titlePlaceholder: "कुछ मजेदार टेक्स्ट",
            untranslatable: "अननुवादी मीम",
            saveAsTmpl: "टेम्पलेट के रूप में सहेजें",
            upload: "सर्वश्रेष्ठ मीम बनाएं!",
            successCreation: "मीम को सफलतापूर्वक अपलोड किया गया है!",
            validation: {
                emptyTitle: "रिक्त लेबल भरें या हटाएँ",
            }
        },
        tmpl: {
            create: "नया टेम्पलेट बनाएं",
            creationDesc: "एक नया टेम्पलेट बनाएं जो अन्य निर्माताओं के लिए उपलब्ध होगा",
            titlePlaceholder: "मजेदार टेक्स्ट",
            upload: "एक अद्भुत टेम्पलेट बनाएं!",
            successCreation: "टेम्पलेट को सफलतापूर्वक बनाया गया है!",
        },
        selectImage: "छवि का चयन करें",
        selectForm: "फ़ॉर्म का चयन करें",
        pleaseSelectNewImage: "कृपया, नई छवि का चयन करें",
        appendTitle: "शीर्षक जोड़ें",
        enterTags: "अंतर्वस्त्रित टैग दर्ज करें:",
        tagsPlaceholder: "सूचनात्मकटैग एकऔरटैग...",
    },
    support: {
        contactUsViaEmail: "आप निम्नलिखित पते पर ईमेल लिखकर हमसे संपर्क कर सकते हैं: ",
        feelFreeToWriteUs: "यदि आपको कोई बग मिलता है या सुधार के लिए आपके पास कोई विचार है, तो कृपया बेझिझक हमें लिखें। हमें आपकी प्रतिक्रिया पाकर बहुत खुशी होगी!"
    }
};