import {makeAutoObservable} from "mobx";
import {RemoteCfg} from "../firebaseConfig";

export class RCStore {
    isInited = false;
    useOAuthBtns = false;
    promoList: RemoteCfg[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setIsInited(b: boolean) {
        this.isInited = true
    }

    getIsInited() {
        return this.isInited
    }

    setRCUseOAuthBtns(b: boolean) {
        this.useOAuthBtns = b
    }

    getRCUseOAuthBtns() {
        return this.useOAuthBtns
    }

    setRCPromoList(list: RemoteCfg[]) {
        this.promoList = list
    }

    appendRCPromoList(rcp: RemoteCfg) {
        this.promoList.push(rcp)
    }

    getRCPromoList() {
        return this.promoList
    }
}