import React from "react";
import {SVGIconProps} from "../svgIcon";

export function BuilderIcon({color, size}: SVGIconProps) {
    return (
        <div style={{width: size, height: size}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                version="1.1"
                viewBox="0 0 35.824 38.89"
            >
                <defs>
                    <linearGradient>
                        <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                    </linearGradient>
                </defs>
                <g transform="translate(-57.944 -105.67)">
                    <g
                        fill={color}
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        strokeDasharray="none"
                        strokeLinejoin="round"
                        strokeOpacity="1"
                        transform="rotate(90)"
                    >
                        <path
                            strokeWidth="1.335"
                            d="M136.76 -84.044H144.56V-67.733H136.76z"
                        ></path>
                        <path
                            strokeWidth="1.335"
                            d="M126.34 -74.255H134.14000000000001V-57.943999999999996H126.34z"
                        ></path>
                        <path
                            strokeWidth="1.335"
                            d="M126.34 -93.768H134.14000000000001V-77.457H126.34z"
                        ></path>
                        <path
                            strokeWidth="1.335"
                            d="M116.02 -84.045H123.82V-67.73400000000001H116.02z"
                        ></path>
                        <path
                            strokeWidth="1.335"
                            d="M105.67 -74.255H113.47V-57.943999999999996H105.67z"
                        ></path>
                        <path
                            strokeWidth="1.335"
                            d="M105.67 -93.768H113.47V-77.457H105.67z"
                        ></path>
                        <path
                            strokeWidth="0.842"
                            d="M116.02 -64.433H123.82V-57.94400000000001H116.02z"
                        ></path>
                        <path
                            strokeWidth="0.842"
                            d="M116.02 -93.768H123.82V-87.279H116.02z"
                        ></path>
                        <path
                            strokeWidth="0.842"
                            d="M136.76 -64.433H144.56V-57.94400000000001H136.76z"
                        ></path>
                        <path
                            strokeWidth="0.842"
                            d="M136.76 -93.768H144.56V-87.279H136.76z"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}