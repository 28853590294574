import additionally_img from "../../images/meme/additionally.png";
import cl from "./Btn.module.css"
import React, {CSSProperties, useEffect, useLayoutEffect, useRef, useState} from "react";

export interface AdditionallyBtnProps {
    elems: JSX.Element[],
}

export default function AdditionallyBtn({elems}: AdditionallyBtnProps) {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);

    function handleClickOutsidePopUp (event: MouseEvent) {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setIsPopupOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsidePopUp);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsidePopUp);
        };
    }, []);

    return (
        <div className={cl.MemePanelBtn}
             onClick={() => setIsPopupOpen(true)}
             ref={popupRef}>
            <img className={cl.MemePanelBtnImg}
                 src={additionally_img} alt="additionally"/>
            <PopUp elems={elems}
                   isPopUpOpen={isPopupOpen}/>
        </div>
    );
}

interface PopUpProps {
    elems: JSX.Element[],
    isPopUpOpen: boolean,
}

function PopUp({elems, isPopUpOpen}: PopUpProps) {
    const popupRef = useRef<null | HTMLDivElement>(null);
    const [yPosStyle, setYPosStyle] = useState<CSSProperties>({top: "0"});
    useLayoutEffect(() => {
        const popup = popupRef.current;            
        function handleTransitionEnd() {
            if (popup) {
                const windowHeight = window.innerHeight;
                const popupHeight = popup.offsetHeight;
                const popupTop = popup.getBoundingClientRect().top;
                if (popupTop + popupHeight > windowHeight) {
                    setYPosStyle({bottom: "0"});
                } else {
                    // setYPosStyle({top: "0"});
                }
            }
        }
        if (popup) {
            popup.addEventListener("transitionend", handleTransitionEnd);

            return () => {
                popup.removeEventListener("transitionend", handleTransitionEnd);
            };
        }
    }, [isPopUpOpen]);

    return (
        <div className={`${cl.AdditionallyBtnPopUpContainer} ${isPopUpOpen ? "" : cl.closed}`}
             ref={popupRef}
             style={yPosStyle}>
            {elems}
        </div>
    );
}