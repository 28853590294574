import React from "react";
import {SVGIconProps} from "../svgIcon";

export function TopMemeDevsIcon({color, size}: SVGIconProps) {
    return (
        <div style={{width: size, height: size}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="35.63mm"
                width={size}
                // height="139.68"
                height={size}
                version="1.1"
                viewBox="0 0 35.63 36.957"
            >
                <defs>
                    <linearGradient>
                        <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                    </linearGradient>
                </defs>
                <g
                    fill={color}
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeDasharray="none"
                    strokeLinejoin="round"
                    strokeOpacity="1"
                    transform="translate(-106.92 -55.398)"
                >
                    <path
                        strokeWidth="1.588"
                        d="M106.92 65.451H114.72V92.35499999999999H106.92z"
                    ></path>
                    <path
                        strokeWidth="1.909"
                        d="M120.84 55.398H128.64000000000001V92.355H120.84z"
                    ></path>
                    <path
                        strokeWidth="1.413"
                        d="M134.75 74.079H142.55V92.35499999999999H134.75z"
                    ></path>
                </g>
            </svg>
        </div>
    );
}