import cl from "./Separator.module.css"

export function LyingSeparator() {
    return (
        <div className={cl.LyingSeparator}></div>
    );
}

export function StandingSeparator() {
    return (
        <div className={cl.StandingSeparator}></div>
    );
}