import {useState} from "react";
import {AxiosError} from "axios";
import {ServerError, ServerErrorImpl} from "../views/Error";
import {ApiError} from "../models/Error";

export function useFetching<P>(callback: (props: P) => Promise<void>) : [(props: P) => Promise<void>, boolean, ServerErrorImpl | null] {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ServerError | null>(null);

    async function fetching(props: P) {
        setIsLoading(true);
        try {
            await callback(props);
        } catch (e) {
            console.log(e);
            const err = new ServerErrorImpl("unknown error", 0)
            if (e instanceof AxiosError<ApiError>) {
                if (e.response && e.response.data.message) {
                    err.message = e.response.data.message;
                }
                if (e.status) {
                    err.status = e.status;
                }
            }
            //for testing purposes only
            // else if (e instanceof ServerErrorImpl) {
            //     err.message = e.message
            //     err.status = e.status
            // }
            setError(err);
        } finally {
            setIsLoading(false);
        }
    }

    return [fetching, isLoading, error];
}