import React, {useContext, useEffect, useState} from "react";
import cl from "./AdditionalPages.module.css"
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/ui/Loader";
import { isDesktop } from "react-device-detect";
import {SiteURL} from "../http";
import {ErrorMsg} from "../components/ui/ErrorPage";
import UrlKey from "../http/urlKey";
import {sendMetric} from "../pkg/Metric";

function Verification() {
    const { authStore } = useContext(Context);
    const { t } = useTranslation();
    const params  = useParams();

    const [isSuccess, setIsSuccess] = useState(false);

    const [ handleVerify, isLoading, error] = useFetching(async () => {
        const idStr = params.id;
        const token = params.token;
        if (idStr && token) {
            await authStore.verifyAccount(parseInt(idStr), token);
            setIsSuccess(true);
            sendMetric("goal", "verification")
        }
    });

    useEffect(() => {
        handleVerify({})
    }, []);

    return (
        <div className={cl.Background}>
            <div className={cl.Container}>
                { isLoading && <Loader/> }
                { isSuccess && t("profile.verification.success") }
                { !isSuccess && error && <ErrorMsg err={error}/> }
                { isDesktop && <a href={SiteURL+UrlKey}>{t("ui.goToWebsite")}</a> }
            </div>
        </div>
    );
}

export default observer(Verification)