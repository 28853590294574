import {IMemeDev} from "../models/MemeDevs";
import {makeAutoObservable} from "mobx";
import IMemeFull from "../models/Memes";

export class Cache {
    memeDev: IMemeDev | null = null;
    hotMemes: [memes: IMemeFull[], page: number, limit: number, total: number, scrollY: number] = [[], 0, 0, 0, 0];
    topMemeDevs: [memeDevs: IMemeDev[], page: number, limit: number, total: number, scrollY: number] = [[], 0, 0, 0, 0];

    constructor() {
        makeAutoObservable(this);
    }

    setMemeDev(user: IMemeDev) {
        this.memeDev = user;
    }

    getMemeDev(id: number) {
        if (this.memeDev) {
            return this.memeDev.id === id ? this.memeDev : null;
        }
        return null;
    }

    setHotMemes(memes: IMemeFull[], page: number, limit: number, total: number, scrollY: number) {
        this.hotMemes = [memes, page, limit, total, scrollY]
    }

    getHotMemes() {
        return this.hotMemes
    }

    setTopMemeDevs(memeDevs: IMemeDev[], page: number, limit: number, total: number, scrollY: number) {
        this.topMemeDevs = [memeDevs, page, limit, total, scrollY]
    }

    getTopMemeDevs() {
        return this.topMemeDevs
    }
}