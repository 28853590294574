import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import urlKey from "./http/urlKey";
import {AuthStore} from "./store/authStore";
import {Cache} from "./store/cache";
import "./ui-translations/i18n"
import {LocaleStore} from "./store/localeStore";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {oauthConfig} from "./config";
import {RCStore} from "./store/rcStore";

interface State {
    authStore: AuthStore,
    localeStore: LocaleStore,
    cache: Cache,
    rcStore: RCStore,
}

export const authStore = new AuthStore();
const localeStore = new LocaleStore();
const cache = new Cache();
export const rcStore = new RCStore();

export const Context = createContext<State>({
    authStore,
    localeStore,
    cache,
    rcStore,
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
        <Context.Provider value={{
            authStore, localeStore, cache, rcStore
        }}>
            <BrowserRouter basename={`${urlKey}`}>
                <GoogleOAuthProvider clientId={oauthConfig.GoogleClientID}>
                    <App/>
                </GoogleOAuthProvider>
            </BrowserRouter>
        </Context.Provider>
    // </React.StrictMode>
);
