import React from "react";
import {SVGIconProps} from "../svgIcon";

function BattleFieldIcon1({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 34.055 40.391"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinejoin="miter"
                strokeOpacity="1"
                transform="translate(-153.22 -53.514)"
            >
                <path
                    fill="none"
                    strokeWidth="2"
                    d="M182.29 81.715c3.15-5.51 3.95-18.632 3.95-18.632l-15.99-8.438-15.99 8.438s.8 13.122 3.96 18.632c2.69 4.715 12.03 10.986 12.03 10.986s9.34-6.271 12.04-10.986z"
                ></path>
                <path
                    fill={color}
                    strokeWidth="1.42"
                    d="M178.7 79.325c2.22-3.872 2.78-13.092 2.78-13.092l-11.23-5.93-11.24 5.93s.56 9.22 2.78 13.092c1.9 3.312 8.46 7.718 8.46 7.718s6.56-4.406 8.45-7.718z"
                ></path>
            </g>
        </svg>
    );
}

export function BattleFieldIcon({color, size}: SVGIconProps) {
    return (
        <div style={{width: size, height: size}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                version="1.1"
                viewBox="0 0 33.854 39.814"
            >
                <defs>
                    <linearGradient>
                        <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                    </linearGradient>
                </defs>
                <g transform="translate(-192.86 -53.784)">
                    <path
                        fill="none"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke={color}
                        strokeDasharray="none"
                        strokeLinejoin="miter"
                        strokeOpacity="1"
                        strokeWidth="7.273"
                        d="M219.69 80.173c2.6-4.54 3.25-15.336 3.25-15.336l-13.15-6.941-13.16 6.941s.66 10.796 3.25 15.336c2.22 3.866 9.91 9.04 9.91 9.04s7.67-5.174 9.9-9.04z"
                    ></path>
                </g>
            </svg>
        </div>
    );
}