import React, {ChangeEvent, CSSProperties, useContext, useEffect, useRef, useState} from "react";
import {Context} from "../../../index";
import cl from "./Profile.module.css"
import {GetMemeDevLevelKey, GetMemeDevRatingStr, IMemeDev} from "../../../models/MemeDevs";
import {useParams} from "react-router-dom";
import {useFetching} from "../../../hooks/useFetching";
import ErrorPage, {ErrorNotify} from "../../ui/ErrorPage";
import {IMemeWithRating} from "../../../models/Memes";
import {useObserving} from "../../../hooks/useObserving";
import Loader, {LoaderLastElement, LoaderPage} from "../../ui/Loader";
import {getMemeExportFileName, MemeContent, MemeRating, TagsPanel} from "../Memes/Memes";
import IBadge from "../../../models/Badge";
import def_avatar_img from "../../images/profile/default-avatar.png";
import premium_star_img from "../../images/profile/premium_star.png";
import ImageLoader from "../../ui/ImageLoader";
import arrow_down from "../../images/arrow_down.png";
import {ProfileSettings} from "./ProfileSettings";
import {useWindowScrollResizing} from "../../../hooks/useWindowScrollResizing";
import ExportMemeBtn from "../../ui/Buttons/ExportMemeBtn";
import ShareBtn from "../../ui/Buttons/ShareBtn";
import MemeDevService from "../../../services/MemeDevService";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import MemeService, {sortDevMemes} from "../../../services/MemeService";
import {Locale} from "../../../models/Locale";
import {getMemePageLink} from "../../Content";
import BadgeService from "../../../services/BadgeService";
import {translationWildcard} from "../../../ui-translations/i18n";
import {DeleteMemeBtn} from "../../ui/Buttons/DeleteMemeBtn";
import camera_img from "../../images/profile/camera_icon.png";
import {toast} from "react-toastify";
import rating_img from "../../images/meme/win_rate.png";
import global_rating_img from "../../images/meme/global_win_rate.png";
import AdditionallyBtn from "../../ui/Buttons/AdditionallyBtn";
import ShowTagsBtn from "../../ui/Buttons/ShowTagsBtn";
import {BlockMemeDevBtn, UnblockMemeDevBtn} from "../../ui/Buttons/BlockUnblockMemeDevBtn";
import {daysLeftUntilTimestamp} from "../../../pkg/Day";
import {sendMetric} from "../../../pkg/Metric";
import { PopUpInfo } from "../../ui/PopUpInfo";

function Profile() {
    const { cache, localeStore} = useContext(Context);
    const params = useParams();
    const id = params.id ? parseInt(params.id) : 0;
    const [memeDev, setMemeDev] = useState<IMemeDev | null>(null);

    const [fetchMemeDev, isLoading, error] = useFetching(async () => {
        let mD = cache.getMemeDev(id);
        if (mD === null) {
            mD = await MemeDevService.getMemeDevPublicInfo(id);
        }
        setMemeDev(mD);
    });

    useEffect(() => {
        fetchMemeDev({});
    }, [params.id])

    useWindowScrollResizing();

    if (error) {
        return <ErrorPage err={error}/>
    }


    return (
        <div key={params.id}>
            { isLoading && <div className={cl.Container}><Loader minHeight={"25vh"}/></div> }
            { memeDev && <MemeDevInfo memeDev={memeDev}
                                      setMemeDev={setMemeDev}/>
            }
            <BadgeList memeDevId={id}/>
            <DevMemes locale={localeStore.getLocale()}
                      memeDevId={id}/>
        </div>
    );
}

export default observer(Profile);

interface MemeDevInfoProps {
    memeDev: IMemeDev,
    setMemeDev(md: IMemeDev): void, // to set isBlocked
}

const MemeDevInfo = observer(({memeDev, setMemeDev}: MemeDevInfoProps) => {
    const { authStore } = useContext(Context);
    const { t} = useTranslation();
    // const [settingsModal, setSettingsModal] = useState(false);
    const [isBlockUnblockBtnDeactivated, setIsBlockUnblockBtnDeactivated] = useState(false);
    let ratingInfo = memeDev.meme_dev_rating_info;
    let lvlKey = GetMemeDevLevelKey(ratingInfo.level);

    const authUser =  authStore.getUser();
    const isCurrentUser = authStore.isAuth && (authUser?.id === memeDev.id);
    useEffect(() => {
        if (authStore.isInited) {
            if (isCurrentUser) {
                sendMetric("goal", "own_profile_page");
            } else {
                sendMetric("goal", "profile_page");
            }
        }
    }, [authStore.isInited]);
    
    const premBtnRef = useRef<HTMLDivElement | null>(null);
    function premStarTitle() {
        // always returns minimum 1 day left
        if (isCurrentUser) {
            // console.log(authStore.premExpiresAt)
            const premDaysLeft = daysLeftUntilTimestamp(authStore.privInfo?.premExpiresAt || 0) + 1;
            if (premDaysLeft > 60) {
                return t("profile.premium.monthsLeft") + Math.round(premDaysLeft / 30)
            } else {
                return t("profile.premium.daysLeft") + premDaysLeft
            }
        } else {
            return t("profile.premium.premiumAccount")
        }
    }
    function premStarStyle() : CSSProperties {
        // if (isCurrentUser) {
        //     return {width: "11em"}
        // } else {
        //     return {whiteSpace: "nowrap"}
        // }
        return {}
    }


    const avatarSizeStyle : CSSProperties = {
        height: "6em",
        width: "6em",
        objectFit: "cover",
        borderRadius: "50%",
    }

    const [selectAvatarBtnOpacity, setSelectAvatarBtnOpacity] = useState(0);
    const [updateAvatar, isPending, error] = useFetching(async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const response = await MemeDevService.updateMemeDevAvatar({Image: file});
            if (authUser) {
                authStore.setUser({...authUser, avatar_url: response.avatar_url});
            }
            event.target.value = "";
            toast.success(t("profile.settings.avatarUpdated"), {autoClose: 1500, style: {zIndex: 99999}})
        }
    })

    useEffect(() => {
        if (error) {
            ErrorNotify({err: error});
        }
    }, [error]);


    return (
        <>
            {/*<ProfileSettings isVisible={settingsModal}*/}
            {/*                 setIsVisible={setSettingsModal}/>*/}
            <div className={cl.Container}
                 style={memeDev.is_premium
                     ? {background: "linear-gradient(to right top, #f3f5f6, #eff1f2 10%, #fcfeff 25%, white 50%, #fcfeff 60%, #eff1f2 85%, #eef0f1 95%)"}
                     : {backgroundColor: "var(--container-color)"}}>
                {/*{ isCurrentUser && <img className={cl.SettingsBtn}*/}
                {/*                        onClick={() => setSettingsModal(true)}*/}
                {/*                        src={def_avatar_img} alt={"settings_img"}/>*/}
                {/*}*/}
                { !isCurrentUser &&
                    <div className={cl.AdditionallyBtnContainer}>
                        <AdditionallyBtn elems={[memeDev.is_blocked
                                ? <UnblockMemeDevBtn key={memeDev.id}
                                                     id={memeDev.id}
                                                     isBtnDeactivated={isBlockUnblockBtnDeactivated}
                                                     setIsBtnDeactivated={setIsBlockUnblockBtnDeactivated}
                                                     callback={() => setMemeDev({...memeDev, is_blocked: false})}/>
                                : <BlockMemeDevBtn key={memeDev.id}
                                                   id={memeDev.id}
                                                   isBtnDeactivated={isBlockUnblockBtnDeactivated}
                                                   setIsBtnDeactivated={setIsBlockUnblockBtnDeactivated}
                                                   callback={() => setMemeDev({...memeDev, is_blocked: true})}/>
                        ]}/>
                    </div>
                }
                <div className={cl.MainInfoContainer}>
                    <div className={cl.AvatarContainerWrapper}>
                        <div className={cl.AvatarContainer}>
                            <ImageLoader key={`${authUser && authUser.avatar_url}`} // for update avatar if current user changes avatar
                                         url={ isCurrentUser ? authUser.avatar_url : memeDev.avatar_url}
                                         altUrl={def_avatar_img}
                                         animStyle={avatarSizeStyle}
                                         imgStyle={avatarSizeStyle}
                                         alt="avatar"/>
                            { isCurrentUser &&
                                <>
                                    <label htmlFor="image_input"
                                           className={cl.AvatarInputLabel}>
                                        <img className={cl.AvatarInputImg}
                                             style={{opacity: selectAvatarBtnOpacity}}
                                             src={camera_img}
                                             alt={"camera_img"}/>
                                    </label>
                                    <input className={cl.AvatarInput}
                                           title={t("profile.settings.selectAvatar")}
                                           type="file"
                                           name="image_input"
                                           id="image_input"
                                           accept={authStore.privInfo?.premExpiresAt ? "image/gif, image/jpeg, image/png, .webp" : "image/jpeg, image/png, .webp"}
                                           onMouseEnter={() => setSelectAvatarBtnOpacity(1)}
                                           onMouseLeave={() => setSelectAvatarBtnOpacity(0)}
                                           onChange={updateAvatar}/>
                                </>
                            }
                        </div>
                    </div>
                    <div style={{flex: "2", textAlign: "left"}}>
                        <div className={cl.NickNameContainer}>
                            {memeDev.nick_name}
                            {memeDev.is_premium &&
                                <div className={cl.PremiumStarImgContainer} 
                                    ref={premBtnRef}>
                                    <img className={cl.PremiumStarImg}
                                        src={premium_star_img}
                                        alt={"premium_star_img"}/>
                                    <PopUpInfo openBtnRef={premBtnRef}
                                                text={premStarTitle()} 
                                                styleProps={premStarStyle()}/>
                                </div>
                            }
                        </div>
                        <div className={cl.NameContainer}>
                            {memeDev.name}
                        </div>
                        <div>{t(`profile.level.${lvlKey}`)}</div>
                    </div>
                </div>
                <div className={cl.RatingInfoContainer}>
                    <div className={cl.RatingInfoRow}>
                        <RatingInfoElem value={ratingInfo.win_battles} name={t("profile.public.wins")}/>
                        <div className={cl.RatingInfoElemSeparator}></div>
                        <RatingInfoElem value={ratingInfo.lost_battles} name={t("profile.public.defeats")}/>
                        <div className={cl.RatingInfoElemSeparator}></div>
                        <RatingInfoElem value={ratingInfo.judged_sum} name={t("profile.public.judged")}/>
                    </div>
                    <div className={cl.RatingInfoRow}>
                        <RatingInfoElem value={ratingInfo.position > 0 ? ratingInfo.position : "-"} name={t("profile.public.position")}/>
                        <div className={cl.RatingInfoElemSeparator}></div>
                        <RatingInfoElem value={ratingInfo.created_memes} name={t("profile.public.memesCount")}/>
                        <div className={cl.RatingInfoElemSeparator}></div>
                        <RatingInfoElem value={GetMemeDevRatingStr(ratingInfo.rating)} name={t("profile.public.rating")}/>
                    </div>
                </div>
            </div>
        </>
    );
})

interface BadgeListProps {
    memeDevId: number,
}

function BadgeList({memeDevId}: BadgeListProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [badges, setBadges] = useState<IBadge[]>([]);
    const langCode = useTranslation().i18n.language;

    function scrollLeft() {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 75;
        }
    }
    function scrollRight() {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 75;
        }
    }

    const [fetchBadges, isLoading, error] = useFetching(async () => {
        const badges = await MemeDevService.getMemeDevBadges(memeDevId, langCode);
        setBadges(badges)
    });

    useEffect(() => {
        fetchBadges({})
    }, [langCode]);

    return (
        <div className={cl.BadgeListContainer}>
            <div className={cl.Scroll}>
                <img src={arrow_down} className={cl.ScrollLeftImg} alt={"arrow_img"} onClick={scrollLeft}/>
            </div>
            <div className={cl.BadgeList} ref={containerRef}>
                { badges.map((badge) => <Badge badge={badge} key={badge.badge_id}/>) }
                { isLoading && <LoaderPage/> }
                { error && <ErrorPage err={error}/> }
            </div>
            <div className={cl.Scroll}>
                <img src={arrow_down} className={cl.ScrollRightImg} alt={"arrow_img"} onClick={scrollRight}/>
            </div>
        </div>
    );
}

interface BadgeProps {
    badge: IBadge,
}

function Badge({badge}: BadgeProps) {
    const { t } = useTranslation();
    const rootClasses = [cl.BadgeModal];
    const [modal, setModal] = useState(false);
    if (modal) {
        rootClasses.push(cl.active);
    }
    let filter = "";
    if (!badge.is_earned) {
        filter = "contrast(30%)";
    }
    const badgeStyle : CSSProperties = {width: "100%", filter: filter};
    const animStyle: CSSProperties = {width: "100%", paddingTop: "100%", borderRadius: "50%", objectFit: "cover"}
    return (
        <>
            <div className={cl.BadgeContainer} onClick={() => setModal(true)}>
                <div className={cl.BadgeImgContainerWrapper}>
                    <div className={cl.BadgeImgContainer}>
                        <ImageLoader imgStyle={badgeStyle}
                                     animStyle={animStyle}
                                     url={badge.img_url}
                                     alt={"badge " + badge.name}/>
                        { badge.count > 0 && <div className={cl.BadgeCountMark}>{badge.count}</div> }
                    </div>
                </div>
                <div className={cl.BadgeNameContainer}>
                    <div className={cl.BadgeName}>{badge.name}</div>
                </div>
            </div>
            <div className={rootClasses.join(" ")} onClick={() => setModal(false)}>
                <div className={cl.BadgeModalContent} onClick={(e) => e.stopPropagation()}>
                    <img style={{maxWidth:"60%", filter: !badge.is_earned ? filter : ""}} src={badge.img_url} alt={"badge " + badge.name}/>
                    <div className={cl.BadgeModalText}
                         style={{fontWeight: "500"}}>
                        {badge.name}
                    </div>
                    { badge.is_earned &&
                        <div className={cl.BadgeModalText}>{badge.description}</div> }
                    { badge.percent_of_earned >= 0 &&
                        <div className={cl.BadgeModalTextSmall}>
                            {t("profile.badges.percentOfPlayersEarned").replace(translationWildcard, `${Math.round(badge.percent_of_earned * 10000) / 100}%`)}
                        </div> }
                    { badge.condition_for_next_level &&
                        <div className={cl.BadgeModalTextSmall}>
                            {`${badge.is_earned ? `${t("profile.badges.nextLevel")}: ` : ""}${badge.condition_for_next_level}`}
                        </div> }
                </div>
            </div>
        </>
    );
}

interface DevMemesProps {
    memeDevId: number,
    locale: Locale,
}

const DevMemes = observer(({memeDevId, locale}: DevMemesProps) => {
    const [memes, setMemes] = useState<IMemeWithRating[]>([]);
    const [page, setPage] = useState(0);
    // const [page, setPage] = useState(-1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [sortType, setSortType] = useState<sortDevMemes>("first-fresh");
    const lastElement = useRef<HTMLDivElement | null>(null);
    const { authStore } = useContext(Context);
    const authUser = authStore.getUser();
    const isCurrentUser = authStore.isAuth && (authUser?.id === memeDevId);

    const [fetchMemes, isLoading, error] = useFetching(async (p: {page: number, sortType: sortDevMemes, curMemes: IMemeWithRating[]}) => {
        console.log("load from page:", p.page)
        const data =
            await MemeService.getMemeDevMemes(memeDevId, locale, p.page, p.sortType);
        setMemes(p.curMemes.concat(data.memes));
        setTotal(data.pagination.total);
        setLimit(data.pagination.limit);
        // setIsObserving(true);
    });

    useEffect(() => {
        fetchMemes({page: page, sortType: sortType, curMemes: memes});
    }, [])

    // useEffect(() => {
    //     if (page >= 0) {
    //         fetchMemes({page: page, sortType: sortType, curMemes: memes});
    //     }
    // }, [page])

    // function handleDelete(memeIdx: number) {
    //     setMemes([]);
    //     setPage(-1);
    // }

    function handleDelete(memeIdx: number) {
        setMemes([]);
        setPage(0);
    }

    // async function handleChangeSortType(type: sortDevMemes) {
    //     setPage(-1);
    //     setSortType(type);
    //     await fetchMemes({page: 0, sortType: type, curMemes: []});
    //     setPage(1);
    // }
    async function handleChangeSortType(type: sortDevMemes) {
        await fetchMemes({page: 0, sortType: type, curMemes: []});
        setPage(0);
        setSortType(type);
    }

    // useObserving(lastElement, limit*page < total, isLoading, () => setPage((page) => page+1));
    useObserving(lastElement, limit*page < total, isLoading, () => {
        fetchMemes({page: page+1, sortType: sortType, curMemes: memes});
        sendMetric("goal", "profile_memes_pagination");
        setPage((page) => page + 1);
    });

    return (
        <div>
            <MemesFilerBar sortType={sortType} setSortType={handleChangeSortType}/>
            { memes.map((meme, index) =>
                <DevMeme key={meme.id}
                         isAuth={authStore.isAuth}
                         meme={meme}
                         canMemeBeDeleted={isCurrentUser}
                         callOnDelete={() => handleDelete(index)}/>)
            }
            <LoaderLastElement isTransparent={!isLoading}
                               lastElemRef={lastElement}/>
            { error && <ErrorPage err={error}/> }
        </div>
    );
})

interface MemesFilterBarProps {
    sortType: sortDevMemes,
    setSortType(sort: sortDevMemes): void,
}

function MemesFilerBar({sortType, setSortType}: MemesFilterBarProps) {
    const { t } = useTranslation();

    function SortRadioButton({value, setSortType, label}: {value: sortDevMemes, setSortType: (v: sortDevMemes) => void, label: string}) {
        return (
            <div className={cl.SortType}>
                <input className={cl.SortTypeRadioBtn} onChange={() => setSortType(value)}
                       type="radio" name="sort" id={value} value={value} checked={sortType === value} />
                <label className={cl.SortTypeName} htmlFor={value}>{label}</label>
            </div>
        );
    }

    return (
        <div className={cl.MemeListHeader}>
            <div className={cl.Greetings}>{t("profile.memes.title")}</div>
            <div className={cl.SortTypeContainer}>
                <div className={cl.SortTypeColumn}>
                    <SortRadioButton value={"first-fresh"} setSortType={setSortType} label={t("profile.memes.sort.firstFresh")}/>
                    <SortRadioButton value={"first-old"} setSortType={setSortType} label={t("profile.memes.sort.firstOld")}/>
                </div>
                <div className={cl.SortTypeColumn}>
                    <SortRadioButton value={"rating-down"} setSortType={setSortType} label={t("profile.memes.sort.ratingDown")}/>
                    <SortRadioButton value={"rating-up"} setSortType={setSortType} label={t("profile.memes.sort.ratingUp")}/>
                </div>
            </div>
        </div>
    );
}

interface DevMemeProps {
    isAuth: boolean,
    meme: IMemeWithRating,
    canMemeBeDeleted: boolean,
    callOnDelete(): void,
}

function DevMeme ({isAuth, meme, canMemeBeDeleted, callOnDelete}: DevMemeProps) {
    const { t } = useTranslation();
    const [isTagsShown, setIsTagShown] = useState(false);
    const rating = Math.round(meme.meme_rating_info.rating * 100);
    let key = 1
    const additionalElems: JSX.Element[] = [<ShowTagsBtn key={key++}
                                                         callback={() => setIsTagShown(!isTagsShown)}
                                                         title={isTagsShown ? t("ui.tag.hideTags") : t("ui.tag.showTags")}/>]
    if (canMemeBeDeleted) {
        additionalElems.push(<DeleteMemeBtn key={key++}
                                            memeId={meme.id}
                                            displayTitle={true}
                                            callOnDelete={callOnDelete}/>);
    }

    return (
        <div className={cl.Container + " " + cl.Meme}>
            <MemeContent id={meme.id}
                         url={meme.url}
                         imgRatio={meme.img_ratio}
                         titles={meme.titles}/>
            <div className={cl.PanelContainer}>
                <div className={cl.PanelBottomLeft}>
                    <div className={cl.PanelBlock}>      
                        <MemeRating ratingInfo={meme.meme_rating_info} ratingType={"rating"}/>
                    </div>
                    {/*<div className={cl.PanelBlock}>*/}
                    {/*    <MemeRating ratingInfo={meme.meme_rating_info} ratingType={"wins"}/>*/}
                    {/*</div>*/}
                    {/*<div className={cl.PanelBlock}>*/}
                    {/*    <MemeRating ratingInfo={meme.meme_rating_info} ratingType={"losts"}/>*/}
                    {/*</div>*/}
                </div>
                <div className={cl.PanelBottomRight}>
                    {/*{ canMemeBeDeleted &&*/}
                    {/*    <DeleteMemeBtn memeId={meme.id}*/}
                    {/*                   callOnDelete={callOnDelete}/>*/}
                    {/*}*/}
                    <ExportMemeBtn meme={meme}
                                   fileName={getMemeExportFileName(false, meme.id)}/>
                    <ShareBtn link={getMemePageLink(meme.id)}
                              callback={async () => await BadgeService.shareMeme(meme.id)}/>
                    { isAuth && <AdditionallyBtn elems={additionalElems}/> }
                </div>
            </div>
            <TagsPanel tags={meme.meme_addon.tags || []}
                       blockedTags={meme.meme_addon.blocked_tags || []}
                       isTagsShown={isTagsShown}/>
        </div>
    );
}

interface RatingInfoElemProps {
    value: number | string,
    name: string,
}

function RatingInfoElem({value, name}: RatingInfoElemProps) {
    return (
        <div className={cl.RatingInfoElem}>
            <div className={cl.RatingInfoValue}>{value}</div>
            <div className={cl.RatingInfoName}>{name}</div>
        </div>
    );
}