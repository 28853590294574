import {makeAutoObservable} from "mobx";
import {Language, Locale, Region} from "../models/Locale";
import LocaleService from "../services/LocaleService";
import MemeDevService from "../services/MemeDevService";
import {accessTokenName, AuthStore} from "./authStore";

export const langListKey = "languageList";
export const regListKey = "regionList";

export const memeLangKey = "memeLanguage";
export const regKey = "region";

export const defMemeLang = "en";
export const defReg = "";

export class LocaleStore {
    private languageList: Language[] = [];
    private regionList: Region[] = [];

    memeLanguage: string = "";
    region: string = "";

    constructor() {
        makeAutoObservable(this);
        const token = localStorage.getItem(accessTokenName);
        let [langList, regList] = this.tryInitLocaleList();
        if (langList.length === 0 || regList.length === 0) {
            this.fetchLocaleList().then(([langListRes, regListRes]) => {
                this.initLocale(langListRes, regListRes);
                if (token && AuthStore.checkJWT(token)) {
                    this.fetchLocale(langListRes, regListRes);
                }
            }, () => {})
        } else {
            this.initLocale(langList, regList);
            if (token && AuthStore.checkJWT(token)) {
                this.fetchLocale(langList, regList);
            }
        }

        // console.log("navigator langs:", navigator.languages)
        // console.log("navigator lang:", navigator.language)
    }

    getLanguageName = (langCode: string) => {
        for (let l of this.languageList) {
            if (l.code === langCode) {
                return l.name
            }
        }
        return "unknown"
    }

    getLanguageList = () => {
        return this.languageList
    }
    private setLanguageList = (langList: Language[]) => {
        this.languageList = langList;
        localStorage.setItem(langListKey, JSON.stringify(langList));
    }

    getRegionList = () => {
        return this.regionList
    }
    private setRegionList = (regList: Region[]) => {
        this.regionList = regList;
        localStorage.setItem(regListKey, JSON.stringify(regList));
    }

    getMemeLanguage = () => {
        if (this.memeLanguage === "") {
            return defMemeLang;
        }
        return this.memeLanguage
    }
    setMemeLanguage = (langCode: string) => {
        this.memeLanguage = langCode;
        localStorage.setItem(memeLangKey, langCode);
    }

    getRegion = () => {
        if (this.region === "") {
            return defReg;
        }
        return this.region
    }
    setRegion = (regCode: string) => {
        this.region = regCode;
        localStorage.setItem(regKey, regCode);
    }

    getLocale = () : Locale => {
        const memeLang = this.memeLanguage === "" ? defMemeLang : this.memeLanguage;
        const reg = this.region === "" ? defReg : this.region;
        return {langCode: memeLang, regCode: reg}
    }

    private tryInitLocaleList = () => {
        let langList: Language[] = [];
        let regList: Region[] = [];
        const langListStr = localStorage.getItem(langListKey);
        const regListStr = localStorage.getItem(regListKey);
        if (langListStr && regListStr) {
            langList = JSON.parse(langListStr);
            regList = JSON.parse(regListStr);
            this.setLanguageList(langList);
            this.setRegionList(regList.sort((a: Region, b: Region) => a.name.localeCompare(b.name)));
        }

        return [langList, regList]
    }

    private initLocale = (langList: Language[], regList: Region[]) => {
        const memeLang = localStorage.getItem(memeLangKey);
        const reg = localStorage.getItem(regKey);
        if (memeLang && reg) {
            this.setMemeLanguage(memeLang);
            this.setRegion(reg);
        } else {
            const [lang, reg] = navigator.language.split("-");
            for (let l of langList) {
                if (l.code === lang) {
                    this.setMemeLanguage(lang);
                    break;
                }
            }
            for (let r of regList) {
                if (r.code === reg) {
                    this.setRegion(reg);
                    break;
                }
            }
            console.log(lang, "-", reg);
        }
    }

    private fetchLocaleList = async () => {
        let langList: Language[] = [];
        let regList: Region[] = [];
        try {
            //get language list and region list concurrently
            [langList, regList] = await Promise.all([
                LocaleService.getLanguages(),
                LocaleService.getRegions()
            ]);
            this.setLanguageList(langList);
            this.setRegionList(regList.sort((a: Region, b: Region) => a.name.localeCompare(b.name)));
        } catch (e) {
            console.log(e);
        }

        return [langList, regList]
    }

    private fetchLocale = async (langList: Language[], regList: Region[]) => {
        // const [lang, reg] = navigator.language.split("-");
        // if (this.memeLanguage === "") {
        //     for (let l of langList) {
        //         if (l.code === lang) {
        //             this.setMemeLanguage(lang);
        //             break;
        //         }
        //     }
        // }
        // if (this.region === "") {
        //     for (let r of regList) {
        //         if (r.code === reg) {
        //             this.setRegion(reg);
        //             break;
        //         }
        //     }
        // }

        //get region and memes language
        try {
            const info = await MemeDevService.getMemeDevPrivateInfo();
            this.setMemeLanguage(info.lang.code);
            this.setRegion(info.reg.code);
        } catch (e) {
            console.log(e);
        }
    }
}