import React from "react";
import {SVGIconProps} from "../svgIcon";

export function TopMemesIcon({color, size}: SVGIconProps) {
    return (
        <div style={{width: size, height: size}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                version="1.1"
                viewBox="0 0 39.343 37.175"
            >
                <defs>
                    <linearGradient>
                        <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                    </linearGradient>
                </defs>
                <g
                    fillOpacity="1"
                    fillRule="nonzero"
                    strokeDasharray="none"
                    strokeLinejoin="miter"
                    strokeOpacity="1"
                    strokeWidth="8"
                    transform="translate(-104.49 -105.73)"
                >
                    <path
                        fill="none"
                        stroke="none"
                        d="M130.37 114.63l-9.61-7.17-11.51 3.36 11.01-4.74 2.85-11.644-1.4 11.904z"
                    ></path>
                    <path
                        fill="none"
                        stroke="none"
                        d="M131.69 119.92l-12.45-13.86-18.57 1.61 18.23-3.86 7.89-16.878-5.77 17.718z"
                    ></path>
                    <path
                        fill="none"
                        stroke="none"
                        d="M132.16 116.68l-9.21-7.97-11.91 2.56 11.51-3.99 3.73-11.589-2.29 11.959z"
                    ></path>
                    <g>
                        <path
                            fill="none"
                            stroke={color}
                            d="M108.21 141.02c.26-.66 11.83-20.1 11.83-20.1l9.64 9.76 8.48-14.53"
                        ></path>
                        <path
                            fill={color}
                            stroke="none"
                            d="M137.19 109.47l-17.28 16.59-5.73-23.26z"
                            transform="matrix(.20096 .73474 -.6934 .21401 192.17 -.167)"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}