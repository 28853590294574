export function GetElementByCurrentDay<T>(elems: T[], offset?: number): T | null {
    if (elems.length === 0) {
        return null
    }
    if (!offset) {
        offset = 0
    }
    const date = new Date();
    const day = date.getDay() * (Math.floor(elems.length/7) + 1) + offset;
    return elems[day%elems.length]
}

// returns floor
export function daysLeftUntilTimestamp(unixTime: number): number {
    const currentTime = Date.now() / 1000; // Convert to seconds
    const timeDifference = unixTime - currentTime;
    if (timeDifference <= 0) {
        return 0;
    }

     // 86400 seconds in a day
    return Math.floor(timeDifference / 86400);
}
