import {useEffect} from "react";
import {contentContainerId} from "../components/Content";
import {isMobile} from "react-device-detect";

// A highly specialized hook to prevent content from
// automatically scrolling when the browser window width changes.
// Can only be used in children of the "content" element
export function useWindowScrollResizing() {
    let scrollY = 0;
    let contentHeight = 1;
    let isHandleResizeProcessing = false;

    const updateScrollY = () => {
        requestAnimationFrame(() => {
            if (isHandleResizeProcessing) {
                return;
            }
            scrollY = window.scrollY;
        });
    };
    const updateContentHeight = () => {
        requestAnimationFrame(() => {
            if (isHandleResizeProcessing) {
                return;
            }
            const contentContainer = document.getElementById(contentContainerId);
            if (contentContainer) {
                contentHeight = contentContainer.clientHeight;
            }
        });
    };

    const handleScroll = () => {
        updateScrollY();
        // console.log("after window.scrollTo (handleScroll() is called by eventListener)");
    };

    const handleResize = () => {
        isHandleResizeProcessing = true;
        requestAnimationFrame(() => {
            const contentContainer = document.getElementById(contentContainerId);
            if (contentContainer) {
                // console.log("before window.scrollTo");
                const scrollRatio = scrollY/contentHeight
                window.scrollTo(0, scrollRatio * contentContainer.clientHeight);

            }
            isHandleResizeProcessing = false;
        } );
    };

    // remove previous event listener if it already exists
    window.removeEventListener('resize', handleResize);
    window.removeEventListener('scroll', handleScroll);


    useEffect(() => {
        if (isMobile) {
            return;
        }
        updateScrollY();
        updateContentHeight();

        const observer = new ResizeObserver(updateContentHeight);
        const contentContainer = document.getElementById(contentContainerId);
        if (contentContainer) {
            observer.observe(contentContainer)
        }
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            observer.disconnect();
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
}