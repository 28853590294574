import $api, {ServerURL} from "../http";
import axios from "axios";

export default class UserAgreementService {
    static async getPrivacy() {
        const res = await axios.get( ServerURL + "/static/user-agreement/privacy.txt")
        return res.data
    }

    static async getTermsOfUse() {
        const res = await $api.get(ServerURL + "/static/user-agreement/terms-of-use.txt")
        return res.data
    }
}