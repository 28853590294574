import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEN from "./en";
import {translationsRU} from "./ru";
import {translationsES} from "./es";
import {translationsHI} from "./hi";

const fallbackLng = 'en'
export const translationWildcard = "%v"

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: fallbackLng,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {translation: translationsEN},
            ru: {translation: translationsRU},
            es: {translation: translationsES},
            hi: {translation: translationsHI},
        },
        detection: getDetectionOpts()
    });

export default i18n;

//interfaces for ui language selectors
export const uiLangs: Langs = {
    en: {name: "english"},
    ru: {name: "русский"},
    es: {name: "spanish"},
    hi: {name: "िंदी"},
};

interface Lang {
    name: string;
}

interface Langs {
    [key: string]: Lang;
}

function getDetectionOpts() {
    return {
        order: ['localStorage', 'sessionStorage', 'cookie', 'navigator', 'querystring', 'htmlTag'],
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'],
        convertDetectedLanguage: (lng: any) => {
            if (typeof lng === 'string') {
                const [lang] = lng.split('-');
                return lang
            }
            return fallbackLng;
        }
    };
}