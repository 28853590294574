import {useEffect} from "react";
import {LoaderPage} from "../../Loader";

interface OAuthCallbackProps {
    provider: "discord" | "twitter"
}

export function OAuthCallback({provider}: OAuthCallbackProps) {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        // console.log(code)
        // console.log(window.opener)
        if (code && window.opener) {
            // console.log('Отправляем код в родительское окно:', code);
            // Попробуем передать код с явным указанием targetOrigin
            window.opener.postMessage({ code, provider }, window.location.origin);

            console.log('Попытка закрыть popup окно');
            window.close(); // Попытка закрыть popup окно
        } else {
            console.log('window.opener не найден или code отсутствует');
            window.close(); // Попытка закрыть popup окно
        }
    }, []);

    return (<LoaderPage/>);
}