import {TokenResponse, useGoogleLogin} from '@react-oauth/google';
import axios, {AxiosResponse} from "axios";
import {OAuthData} from "../../../../views/LoginRegister";
import {GoogleUserInfo} from "../../../../models/Auth";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {Context} from "../../../../index";
import cl from "./OAuthBtn.module.css";
import google_logo_img from "../../../images/login-register/google_logo.png"

interface GoogleLoginButtonProps {
    getUser: (data: OAuthData) => Promise<void>
}

// React <App/> component must be wrapped by <GoogleOAuthProvider> (see index.tsx file)
export const GoogleLoginButton = observer(({getUser}: GoogleLoginButtonProps) => {
    const {localeStore} = useContext(Context);
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                const oauthData = await onSuccessGoogleLogin(tokenResponse, localeStore.memeLanguage, localeStore.region);
                await getUser(oauthData);
            } catch (e) {
                console.log(e);
            }
        },
        onError: errorResponse => console.log(errorResponse),
    });

    return (
        <>
            <div onClick={() => googleLogin()}
                 className={cl.OAuthButton}>
                <div className={cl.OAuthImgContainer}>
                    <img className={cl.OAuthLogoImg} src={google_logo_img} alt={"login with google"}/>
                </div>
            </div>
        </>
    );
})

async function onSuccessGoogleLogin(response: TokenResponse, lang: string, reg: string): Promise<OAuthData> {
    // console.log(response);
    const userInfo: AxiosResponse<GoogleUserInfo> = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${response.access_token}` } },
    );
    // console.log(userInfo);
    return {
        type: "google",
        userId: userInfo.data.sub,
        accessToken: response.access_token,
        avatarUrl: userInfo.data.picture,
        email: userInfo.data.email,
        emailVerified: userInfo.data.email_verified,
        name: userInfo.data.name,
        nickName: "",
        lang: lang,
        reg: reg,
        getOAuthData: undefined
    };
}