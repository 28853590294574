import {CreateMemeInfo} from "../models/Memes";
import {DraftStore} from "../components/content/Builder/Constructor/DraftStore";

export interface CreateMemeProps {
    templateId?: number,        // required if creating from existing template, otherwise not
    withTemplate: boolean,
    langCode: string,
    regCode: string,
}

export function toModelCreateMemeInfo(draft: DraftStore, props: CreateMemeProps) {
    const isLocal = draft.getIsLocal();
    const titles = draft.titleStore.getTitles();
    const formattedTags = draft.tags.map(tag => tag.replace("#", ""));

    const createInfo : CreateMemeInfo = {
        titles: isLocal ? titles.filter(title => title.text.length > 0) : titles, //drop empty titles if meme is local
        img_ratio: draft.imgRatio,
        is_local: isLocal,
        lang: props.langCode,
        reg: props.regCode,
        meme_addon: {
            tags: formattedTags,
        },
    }

    if (props.withTemplate && !props.templateId) {
        createInfo.template_addon = {
            form: draft.getForm(),
            tags: formattedTags,
        };
    }

    if (props.templateId) {
        createInfo.template_id = props.templateId;
    }

    return createInfo;
}