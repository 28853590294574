export const latinCyrillicFonts = [
    'arial black',
    'comic sans ms',
    'impact',
    'roboto',
    'times new roman',
    'lobster'
] as const;

export const hindiFonts = [
    'hind bold',
    'mangal',
    'mangal bold',
    'nirmala ui'
] as const;

// getFontListByLang returns latinCyrillicFonts by default
export function getFontListByLang(langCode: string) {
    if (langCode === "hi") {
        return hindiFonts
    }

    return latinCyrillicFonts
}