export interface ServerError extends Error {
    status: number,
}

export class ServerErrorImpl extends Error implements ServerError {
    status: number;

    constructor(message: string, code: number) {
        super(message);
        this.name = 'ServerError';
        this.status = code;
    }
}
