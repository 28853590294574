import $api from "../http";
import {CreateResponse} from "../models/CreateResponse";
import {CreateMemeTemplateData, ITemplate, ITemplatePreviews} from "../models/Templates";
import {FormType} from "../components/images/forms/forms";

export default class TemplateService {
    static async createTemplate(data: CreateMemeTemplateData) {
        return await $api.postForm<CreateResponse>("/v1/templates", data);
    }

    static async getTemplate(id: number) {
        const response = await $api.get<ITemplate>(`/v1/templates/${id}`);
        return response.data;
    }

    static async getPreviewList(lang: string, tags: string, form: FormType, page: number) {
        const limit = 10;
        const response =
            await $api.get<ITemplatePreviews>(`/v1/templates?lang=${lang}&tags=${tags}&form=${form}&offset=${page*limit}&limit=${limit}`);
        return response.data;
    }
}