import {globalRegion, LoginFormData, OAuthData, RegistrationFormData} from "../views/LoginRegister";
import {LoginCredentials, OAuthGetUserInfo, RegistrationCredentials} from "../models/LoginRegister";
import {globalRegionCode, Language, Region} from "../models/Locale";

export function toModelLoginCreds(data: LoginFormData) : LoginCredentials {
    return {
        email: data.email,
        password: data.password,
    };
}

export function toModelRegistrationCreds(data: RegistrationFormData) : RegistrationCredentials {
    return {
        email: data.email,
        password: data.password,
        nick_name: data.nickName,
        // birth_date: data.birthDate,
        lang: data.lang,
        reg: data.reg,
        summoner: data.summoner,
    };
}

export function toModelOAuthGetUserInfo(data: OAuthData): OAuthGetUserInfo {
    return {
        user_id: data.userId,
        type: data.type,
        access_token: data.accessToken,
        email: data.email,
        email_verified: data.emailVerified,
        name: data.name,
        nick_name: data.nickName,
        avatar_url: data.avatarUrl,
        lang: data.lang,
        reg: data.reg,
        get_oauth_data: data.getOAuthData
    }
}

export function toRegionCode(regName: string, regions: Region[]) {
    if (regName === globalRegion) {
        return globalRegionCode
    }
    for (let reg of regions) {
        if (reg.name === regName) {
            return reg.code;
        }
    }

    return ""
}

export function toLanguageCode(langName: string, languages: Language[]) {
    for (let lang of languages) {
        if (lang.name === langName) {
            return lang.code;
        }
    }

    return ""
}

export function toViewRegionList(regions: Region[]) : string[] {
    const regList : string[] = [];
    for (let reg of regions) {
        if (reg.code !== globalRegionCode) {
            regList.push(reg.name);
        }
    }

    return regList;
}

export function toViewLanguageList(languages: Language[]) : string[] {
    const langList : string[] = [];
    for (let lang of languages) {
        langList.push(lang.name);
    }

    return langList;
}