import {DraftStore} from "../components/content/Builder/Constructor/DraftStore";
import {CreateMemeTemplateInfo, ITemplateTitle} from "../models/Templates";

export interface CreateTemplateProps {
    langCode: string,
    regCode: string,
}

export function toModelCreateMemeTemplateInfo(draft: DraftStore, props: CreateTemplateProps) {
    const createInfo: CreateMemeTemplateInfo = {
        img_ratio: draft.imgRatio,
        lang: props.langCode,
        reg: props.regCode,
        template_addon: {
            form: draft.form,
            tags: draft.tags.map(tag => tag.replace("#", "")),
        },
        titles: []
    }

    const draftTitles = draft.titleStore.getTitles();
    for (let i = 0; i < draftTitles.length; i++) {
        const title: ITemplateTitle = {
            angle: draftTitles[i].angle,
            font: draftTitles[i].font,
            font_size: draftTitles[i].font_size,
            text_color: draftTitles[i].text_color,
            x_ratio: draftTitles[i].x_ratio,
            y_ratio: draftTitles[i].y_ratio,
        }
        createInfo.titles.push(title);
    }

    return createInfo;
}