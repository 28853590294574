import React, {CSSProperties, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useFetching} from "../../../hooks/useFetching";
import {useObserving} from "../../../hooks/useObserving";
import cl from "./TopMemeDevs.module.css";
import {
    GetMemeDevLevelKey,
    GetMemeDevRatingStr,
    IMemeDev,
} from "../../../models/MemeDevs";
import win_rate_img from "../../images/meme/win_rate.png";
import meme_img from "../../images/meme_icon.png";
import rating_img from "../../images/rating_star.png";
import {NavLink} from "react-router-dom";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import ErrorPage from "../../ui/ErrorPage";
import {LoaderLastElement, LoaderPage} from "../../ui/Loader";
import ImageLoader from "../../ui/ImageLoader";
import {useWindowScrollResizing} from "../../../hooks/useWindowScrollResizing";
import {useTranslation} from "react-i18next";
import MemeDevService from "../../../services/MemeDevService";
import def_avatar_img from "../../images/profile/default-avatar.png";
import premium_star_img from "../../images/profile/premium_star.png";
import {sendMetric} from "../../../pkg/Metric";
import {contentContainerId} from "../../Content";

function TopMemeDevs() {
    const { localeStore, cache } = useContext(Context);
    const [cachedMemeDevs, cachedPage, cachedLimit, cachedTotal, cachedScrollY] = cache.getTopMemeDevs(); // cache should be cleaned by clicking at the navbar top-meme-devs link
    const [memeDevs, setMemeDevs] = useState<IMemeDev[]>(cachedMemeDevs);
    const [total, setTotal] = useState(cachedTotal);
    const [limit, setLimit] = useState(cachedLimit);
    const [page, setPage] = useState(cachedPage);
    const lastElement = useRef<HTMLDivElement | null>(null);

    const [fetchMemeDevs, isLoading, error] = useFetching(async (p: {page: number}) => {
        const data = await MemeDevService.getTopMemeDevs(localeStore.getRegion(), p.page);
        setMemeDevs(memeDevs.concat(data.meme_devs));
        setTotal(data.pagination.total);
        setLimit(data.pagination.limit);
        if (p.page === 0) {
            sendMetric("goal", "top_meme_devs_page");
        }
        if (p.page > 0) {
            sendMetric("goal", "top_meme_devs_pagination");
        }
    });

    useLayoutEffect(() => {
        return () => {
            cache.setTopMemeDevs(memeDevs, page, limit, total, window.scrollY);
        };
    }, [memeDevs, page, limit, total]);

    useEffect(() => {
        if (cachedMemeDevs.length === 0) {
            fetchMemeDevs({page: 0});
        }
        const contentContainer = document.getElementById(contentContainerId);
        if (cachedMemeDevs.length > 0 && contentContainer && cachedScrollY < contentContainer.clientHeight) { // last check for preserve overscroll if user changed window height
            window.scrollTo(0, cachedScrollY);
        }
    }, []);

    useObserving(lastElement, page*limit < total, isLoading, () => {
        fetchMemeDevs({page: page + 1});
        setPage(page + 1);
    });

    useWindowScrollResizing();

    return (
        <>
            { memeDevs.length > 0 &&
                <div className={cl.memeDevsContainer}>
                    { memeDevs.map((memeDev, index) =>
                        <MemeDev memeDev={memeDev}
                                 key={memeDev.id}
                                 index={index+1}/>)
                    }
                </div>
            }
            { memeDevs.length === 0 && <LoaderPage/> }
            <LoaderLastElement isTransparent={!isLoading || memeDevs.length === 0}
                               lastElemRef={lastElement}/>
            { error && <ErrorPage err={error}/> }
        </>
    );
}

export default observer(TopMemeDevs);

interface MemeDevProps {
    memeDev: IMemeDev,
    index: number
}

function MemeDev({memeDev, index}: MemeDevProps) {
    const {cache} = useContext(Context);
    const { t } = useTranslation();

    let ratingInfo = memeDev.meme_dev_rating_info;
    let winRate: number;
    if (ratingInfo.win_battles === 0 && ratingInfo.lost_battles === 0) {
        winRate = 50;
    } else if (ratingInfo.lost_battles === 0) {
        winRate = 100;
    } else {
        winRate = Math.round(ratingInfo.win_battles/(ratingInfo.win_battles+ratingInfo.lost_battles)*100);
    }
    let ratingStr = GetMemeDevRatingStr(ratingInfo.rating);
    const lvlKey = GetMemeDevLevelKey(memeDev.meme_dev_rating_info.level);

    let baseAvatarStyle : CSSProperties = {
        height: "4.5em",
        width: "4.5em",
        objectFit: "cover",
        borderRadius: "50%",
        marginLeft: "2vmin",
        marginRight: "2vmin",
        marginTop: "0.2em",
    }
    let avatarStyle : CSSProperties;

    let clAddon = "";
    let clParity = "";
    if (index === 1) {
        clAddon = cl.first;
        avatarStyle = {border: "0.15em solid #ffbc19", ...baseAvatarStyle};
    } else if (index === 2) {
        clAddon = cl.second;
        avatarStyle = {border: "0.15em solid darkgray", ...baseAvatarStyle};
    } else if (index === 3) {
        clAddon = cl.third;
        avatarStyle = {border: "0.15em solid #cd7f32", ...baseAvatarStyle};
    } else {
        if (index < 11) {
            avatarStyle = {border: "0.1em solid rgb(24, 118, 210)", ...baseAvatarStyle};
            clAddon = cl.ten;
        } else {
            avatarStyle = baseAvatarStyle;
            clAddon = cl.std;
        }

        if (index % 2 === 0) {
            clParity = cl.even;
        } else {
            clParity = cl.odd;
        }
    }

    return (
        <NavLink onClick={() => cache.setMemeDev(memeDev)} to={`/meme-devs/${memeDev.id}`}>
            <div className={cl.memeDevContainer+ " " + clAddon + " " + clParity}>
                <div style={{display: "flex"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <div className={cl.position+" "+clAddon}>{"# " + memeDev.meme_dev_rating_info.position}</div>
                            <ImageLoader imgStyle={avatarStyle}
                                         animStyle={avatarStyle}
                                         url={memeDev.avatar_url}
                                         altUrl={def_avatar_img}
                                         alt="avatar_img"/>
                    </div>
                    <div className={cl.memeDevInfo}>
                        <div className={cl.NickNameContainer}>
                            {memeDev.nick_name}
                            {memeDev.is_premium &&
                                <img className={cl.PremiumStarImg}
                                     title={t("profile.premium.premiumAccount")}
                                     src={premium_star_img}
                                     alt={"premium_star_img"}/>
                            }
                        </div>
                        <div style={{fontWeight: "500"}}>{t(`profile.level.${lvlKey}`)}</div>
                        <div className={cl.ratingInfoContainer}>
                            <div className={cl.ratingInfoSection}>
                                <img className={cl.ratingInfoImg} src={win_rate_img} alt="win_rate_img"/>
                                <div className={cl.ratingInfoVal}>{ winRate + "%" }</div>
                            </div>
                            <div className={cl.ratingInfoSection}>
                                <img className={cl.ratingInfoImg} src={meme_img} alt="meme_img"/>
                                <div className={cl.ratingInfoVal}>{ ratingInfo.created_memes }</div>
                            </div>
                            <div className={cl.ratingInfoSection}>
                                <img className={cl.ratingInfoImg} src={rating_img} alt="rating_img"/>
                                <div className={cl.ratingInfoVal}>{ ratingStr }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*{index > 10 ? <div className={cl.separator}></div> : <></>}*/}
        </NavLink>
    );
}