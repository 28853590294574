import cl from "./Loader.module.css";
import {MutableRefObject} from "react";

interface LoaderProps {
    minHeight?: string,
    minWidth?: string,
}

export default function Loader({minHeight, minWidth}: LoaderProps) {
    return (
        <div className={cl.Loader}
             style={{minHeight: minHeight, minWidth: minWidth}}>
            {[...Array(5)].map((_, index) => (
                <div key={index} className={`${cl.Dot} ${cl[`Dot-${index + 1}`]}`} />
            ))}
        </div>
    );
}

export function LoaderPage() {
    return (
        <div className={cl.LoaderContainer}>
            <Loader/>
        </div>
    );
}

interface LoaderLastElementProps {
    isTransparent: boolean,
    lastElemRef: MutableRefObject<HTMLDivElement | null>,
}

export function LoaderLastElement({isTransparent, lastElemRef}: LoaderLastElementProps) {
    return (
        <div style={{minHeight: "25px", minWidth: "25px"}}
             ref={lastElemRef}>
            { !isTransparent && <Loader/> }
        </div>
    );
}