export const translationsRU = {
    common: {
        and: "и",
        or: "или",
    },
    ui: {
        defErrMsg: "Что-то пошло не так :(",
        notFoundErrMsg: "404. Странциа не найдена",
        loading: "Загрузка...",
        imageExportMsg: "Загрузка изображения вот-вот начнется",
        linkCopied: "Ссылка скопирована в буфер обмена",
        pending: "Ожидайте...",
        goToWebsite: "Перейти на сайт",
        localMeme: "Локальный мем",
        delete: "Удалить",
        submit: "Подтвердить",
        cancel: "Отмена",
        tag: {
            showTags: "Показать теги",
            hideTags: "Скрыть теги",
            clickToBlock: "Кликните, чтобы добавить тег в черный спискок",
            clickToUnblock: "Кликните, чтобы исключить тег из черного списка",
            tagBlocked: "Тег заблокирован. Мемы с данным тегом будут исключены из подборки",
            tagUnblocked: "Тег разблокирован"
        },
        memeDev: {
            block: "Заблокировать",
            unblock: "Разблокировать",
            memeDevBlocked: "Пользователь заблокирован. Его контент будет исключен из подборки",
            memeDevUnblocked: "Пользователь разблокирован",
        },
        betaInfo: "Дорогой Пользователь,\nбольшое спасибо за участие в бета-тесте! Если ты нашел баг, или у тебя есть идеи по улучшению, напиши нам на почту:",
        mobileMarketNoticeTitle: "Наше приложение доступно в магазинах:",
        cookieAttention: {
            title: "Мы используем файлы cookie",
            info: "Пользуясь нашим сайтом, вы соглашаетесь на использование файлов cookie. Мы применяем файлы cookie для авторизации. Это помогает нам обеспечить удобство использования веб-сайта.",
            understood: "Понятно",
        },
        featureTemporarilyDisabled: "Данная функция временно отключена, мы над ней работаем",
        memeRatingTitleGlobal: "Расчетное значение глобального рейтинга. Зависит от количества побед, поражений и общего числа голосов",
        memeRatingTitleLocal: "Расчетное значение локального рейтинга. Зависит от количества побед, поражений и общего числа голосов",
        memeWins: "Количество побед",
        memeLosts: "Количество поражений",
    },
    apiError: {
        _400: {
            invalidTemplateForm: "Некорректная форма шаблона",
            invalidBirthDate: "Некорректная дата рождения",
            imageMissing: "Не получено изображение",
            emailIsNotValid: "Email невалиден, или пользователь с таким email уже существует",
        },
        _401: {
            wrongLoginPwd: "Неверный логин и/или пароль",
            wrongPwd: "Неверный пароль",
        },
        _403: {
            accountNotVerified: "Ваш аккаунт не верифицирован",
            accessDenied: "Доступ запрещен",
        },
        _404: {
            notFound: "Запрашиваемый ресурс не найден",
            memeNotFound: "Мем не найден",
            userNotFound: "Пользователь не найден",
            templateNotFound: "Шаблон не найден",
            langNotSupported: "Язык не поддерживается",
            regNotSupported: "Регион не существует или не поддерживается",
            activationLink: "Ссылка для активации аккаунта некорректна, или срок ее действия истек",
            pwdRestoreLink: "Ссылка для восстановления пароля некорректна, или срок ее действия истек",
            unknownReportCategory: "Неизвестная категория жалобы",
        },
        _409: {
            cantJudge: "Данная пара мемов недоступна для судейства",
            emailExists: "Пользователь с таким email уже существует",
            nicknameExists: "Пользователь с таким никнеймом уже существует",
            alreadyReported: "Вы уже пожаловались на этот пост",
        },
        _413: {
            imgTooLarge: "Размер файла изображения не должен превышать 10 MB",
            stringBodyTooLarge: "Размер текстовых данных не должен превышать 10 KB",
        },
        _422: {
            imgFileType: "Формат переданного изображения не поддерживается. Выберите jpg/jpeg или png",
            imgRatio: "Слишком вытянутое изображение, соотношение сторон не должно превышать 10 к 1",
            lang: "Выбранный язык не поддерживается",
            reg: "Выбранный регион не поддерживается",
            userCreation: {
                birthDate: "Ваш возраст должен составлять от 12 до 100 лет",
                email: "Почта должна иметь корректный формат",
                firstName: "Длина имени не должна превышать 100 символов",
                lastName: "Длина фамилии не должна превышать 100 символов",
                nickname: "Длина псевдонима должна быть между 3 и 20 символами, псевдоним должен содержать только латинские буквы и цифры и не должен состоять только из цифр",
                password: "Длина пароля должна быть между 6 и 20 символами",
            },
            memeOrTemplateCreation: {
                titles: {
                    memeTitles: "Без надписей можно создать только непереводимый мем",
                    tmplTitles: "Нельзя создать шаблон без надписей",
                    font_size: "Размер шрифта слишком велик или слишком мал",
                    text: "Надпись не должна быть пустой, и длина надписи не должна превышать 2000 символов",
                    angle: "Недопустимый угол наклона надписи",
                    textColor: "Недопустимый цвет надписи",
                    xRatio: "Надпись выходит за вертикальные границы изображения",
                    yRatio: "Надпись выходит за горизонтальные границы изображения",
                },
                templateAddon: {
                    form: "Некорректная форма мема или шаблона",
                    tags: "Теги не должны быть пустыми и не должны иметь дубликатов. Суммарное количество символов должно быть не более 100",
                },
            }
        },
        _429: {
            creationLimit: "Превышен лимит публикаций. Доступно не более 10 публикаций мемов и 10 шаблонов в день",
            emailSendingLimit: "Превышен лимит отправки писем. Доступна отправка не более 1 письма каждую минуту и не более 10 писем каждую неделю",
        },
    },
    navbar: {
        hotMemes: "Горячее",
        topMemeDevs: "Топ авторов",
        battleField: "Битва",
        builder: "Конструктор",

        localeSettingsTitle: "Язык мемов",
        additional: "Дополнительно",
        support: "Связаться с поддержкой",
    },
    report: {
        popUpTitle: "Пожаловаться",
        modalTitle: "Выберите причину жалобы",
        spam: "Спам",
        violenceOrHostility: "Насилие или вражда",
        adultContent: "Контент для взрослых",
        other: "Другое",
        translation: "Некорректный перевод текста",
        position: "Некорректное расположение текста",
        successSending: "Жалоба отправлена",
    },
    authForm: {
        login: {
            title: "Войти",
            email: "Почта",
            password: "Пароль",
            forgotPwd: "Забыли пароль?",
            restorePwd: "Восстановить пароль",
        },
        signUp: {
            title: "Зарегистрироваться",
            nickname: "Псевдоним (латинские буквы и цифры)",
            firstname: "Имя",
            lastname: "Фамилия",
            birthDate: "Дата рождения",
            birthDateTooltip: "Это поможет рекомендовать вам лучшие мемы",
            enterYourAge: "Укажите ваш возраст",
            yourAge: "Ваш возраст",
            memesLang: "Язык мемов",
            region: "Ваш регион",
            email: "Почта",
            password: "Пароль",
            submitPassword: "Подтвердите пароль",
            summoner: "Id или никнейм пригласившего",
            summonerTooltip: "Если вас пригласил друг, не забудьте указать его никнейм или Id",
            agreement: {
                agree: "Я согласен с",
                privacy: "политикой конфиденциальности",
                and: "и",
                terms: "правилами использования",
                ending: ""
            },
            agreementModal: {
                title: "Пользовательское соглашение",
                byStaying: "Оставаясь на сайте, вы принимаете",
                privacy: "политику конфиденциальности",
                and: "и",
                terms: "правила использования",
                ending: "",
                acceptBtn: "Принимаю"
            },
            successSignUp: "На указанную почту было отправлено письмо со ссылкой для верификации аккаунта",
            error: {
                pwdDontMatch: "Введенные пароли не совпадают",
                age: "Чтобы зарегистрироваться, вам должно быть не меньше 12 и не больше 100 лет",
            }
        },
    },
    profile: {
        premium: {
            premiumAccount: "Премиум аккаунт",
            daysLeft: "Дней до окончания премиум-статуса: ",
            monthsLeft: "Месяцев до окончания премиум-статуса: "
        },
        popUp: {
            myProfile: "Мой профиль",
            settings: "Настройки",
            logout: "Выйти",
        },
        public: {
            wins: "Победы",
            defeats: "Поражения",
            judged: "Оценил",
            position: "Место",
            memesCount: "Мемы",
            rating: "Рейтинг",
        },
        level: {
            l1: "Младший мемолог",
            l2: "Уверенный мемье",
            l3: "Старший разработчик мемов",
            l4: "Мемный-гуру",
            l5: "Почти бог мемов",
            l6: "Мемный бог",
        },
        memes: {
            title: "Авторские мемы",
            sort: {
                firstFresh: "Сначала новые",
                firstOld: "Сначала старые",
                ratingDown: "Рейтинг по убыванию",
                ratingUp: "Рейтинг по возрастанию",
            },
            areYouSureDeleteMeme: "Вы уверены, что хотите удалить мем?",
            successDeletion: "Мем удален",
        },
        badges: {
            percentOfPlayersEarned: "Данное достижение есть у %v игроков",
            nextLevel: "Следующий уровень",
        },
        settings: {
            title: "Настройки профиля",
            nicknameDesc: "Изменить псевдоним",
            selectAvatar: "Выбрать аватар",
            avatarUpdated: "Аватар был успешно обновлен",
        },
        advancedSettings: {
            title: "Настройки",
            locale: {
                title: "Локаль",
                memesLangName: "Язык",
                memesLangDesc: "Изменить язык мемов",
                regName: "Регион",
                regDesc: "Изменить географичекий регион",
            },
            security: {
                title: "Безопасность",
                emailName: "Электронная почта",
                emailDesc: "Ваша электронная почта",
                pwdName: "Пароль",
                changePwd: "Изменить пароль",
                fullLogoutName: "Выйти",
                fullLogoutDesc: "Выйти со всех устройств",
                enterCurrentPwd: "Введите текущий пароль",
                enterNewPwd: "Введите новый пароль",
                pwdUpdateOrRestoreSuccess: "Ваш пароль был изменен",
                enterEmailToRestorePwd: "Введите email, на который зарегистрирован ваш аккаунт",
                sendRestoreLink: "Отправить ссылку на восстановление",
                emailToRestorePwdSent: "Ссылка на восстановление отправлена на указанный email",
            },
            blackList: {
                title: "Черный список",
                clearBlackList: "Очистить черный список",
                clearBlockedTagsSubmitDesc: "Вы уверены, что хотите очистить список заблокированных тегов?",
                clearBlockedMemeDevsSubmitDesc: "Вы уверены, что хотите очистить список заблокированных пользоватлей?",
                blockedTagsName: "Заблокированные теги",
                noBlockedTags: "Нет заблокированных тегов",
                blockedMemeDevsName: "Заблокированные разработчики мемов",
                noBlockedMemeDevs: "Нет заблокированных разработчиков мемов",
            },
            additional: {
                title: "Дополнительно",
                deleteAccount: "Удалить аккаунт",
                restoreAccount: "Восстановить аккаунт",
                deleteAccountAttentionTitle: "Вы хотите удалить свой аккаунт?",
                deleteAccountAttentionDesc: "Вы можете отменить удаление аккаунта в течение 14 дней. После этого срока ваш аккаунт будет удален навсегда. Ваши личные даные будут удалены. Созданные вами мемы станут анонимными.",
                acceptAccountDeletion: "Удалить",
                accountDeletionMsg: "Ваш аккаунт будет удален через %v дней",
                accountDeletionCanceledMsg: "Вы отменили удаление аккаунта",
            },
        },
        verification: {
            success: "Ваш аккаунт верифицирован",
            bannerMsg: "Аккаунт не подтвержден",
            bannerBtn: "Перейти в настройки",
            settings: {
                title: "Верификация аккаунта",
                accountNotVerifiedMsg: "Ваш аккаунт не верифицирован. Пожалуйста, укажите и подтвердите вашу почту",
                sendEmail: "Подтвердить почту",
                emailIsSent: "На вашу почту был отправлен email для подтверждения аккаунта",
                nextEmailRemaining: "Чтобы повторить отправку, подождите.\nОсталось (секунд):",
            }
        },
    },
    topMemes: {
        title: "Подборка горячих мемов для вас",
        loginToSeeMore: "Авторизуйтесь, чтобы увидеть больше мемов",
    },
    duncanChest: {
        title: "Отборные мемы на эту неделю",
    },
    battleField: {
        title: "Выбери победителя",
        left: "Левый",
        right: "Правый",
        fullScreen: "Войти в полноэкранный режим",
        collapse: "Свернуть",
        allBattlesJudged: "Вы судействовали во всех битвах, доступных на текущий момент",
        signUpInfo: "Регистрируйся, и мемы на разных устройствах не будут повторяться",
    },
    builder: {
        searchTmpl: {
            filterByForm: "Фильтр по форме",
            searchByTags: "Поиск по тегам",
            noSearchResults: "Поиск не дал результатов",
            expandPreviewList: "Развернуть на весь экран",
            collapsePreviewList: "Свернуть",
        },
        meme: {
            originalLang: "Язык оригинала",
            create: "Создать мем",
            creationDesc: "Режим создания нового мема",
            editableTitlePlaceholder: "Введите текст...",
            titlePlaceholder: "Забавный текст",
            untranslatable: "Непереводимый мем",
            saveAsTmpl: "Сохранить как шаблон",
            upload: "Создать лучший мем!",
            successCreation: "Мем был успешно загружен!",
            validation: {
                emptyTitle: "Заполните или удалите пустые надписи",
            }
        },
        tmpl: {
            create: "Создать шаблон",
            creationDesc: "Создать новый шаблон, который будет доступен другим авторам",
            titlePlaceholder: "Смешной текст",
            upload: "Создать шикарный шаблон!",
            successCreation: "Шаблон был успешно загружен!",
        },
        selectImage: "Выберите картинку",
        selectForm: "Выберите форму",
        pleaseSelectNewImage: "Пожалуйста, выберите новое изображение",
        appendTitle: "Добавить надпись",
        enterTags: "Введите теги, разделенные пробелами:",
        tagsPlaceholder: "ИнформативныйТег ЕщеОдинТег...",
    },
    support: {
        contactUsViaEmail: "Вы можете связаться с нами, написав электронное письмо на следующий адрес: ",
        feelFreeToWriteUs: "Если вы обнаружили баг, или у вас есть идеи по улучшению, пожалуйста, не стесняйтесь писать нам. Мы будем очень рады обратной связи!"
    }
};