import React, {useState} from "react";
import cl from "./Content.module.css"
import {Outlet} from "react-router-dom";
import urlKey from "../http/urlKey";
import {SiteURL} from "../http";
import {useTranslation} from "react-i18next";
import {config} from "../config";
import {isMobile} from "react-device-detect";
import {CloseBtn} from "./ui/Buttons/CloseBtn";

export const contentContainerId = "contentContainer";

export type ContentPage = `` | "/hot-memes" | "/top-meme-devs" | "/battle-field" | "/builder" | "/404"

interface ContentProps {
    isNavbarNarrow: boolean,
}

function Content({isNavbarNarrow}: ContentProps) {
    // const { t } = useTranslation();
    // const isBetaInfoBannerClosedKey = "isBetaInfoBannerClosed";
    // const [isBetaInfoBannerHidden, setIsBetaInfoBannerHidden] = useState(!!localStorage.getItem(isBetaInfoBannerClosedKey));
    // function handleBetaInfoBannerClose() {
    //     setIsBetaInfoBannerHidden(true);
    //     localStorage.setItem(isBetaInfoBannerClosedKey, "true");
    // }
    // const supportEmail = config.SupportEmail;
    return (
        <div className={cl.Content}
             style={isNavbarNarrow ? {width: "var(--content-for-narrow-navbar-width)"} : {width: "var(--content-width)"}}
             id={contentContainerId}>
            <Outlet/>
            {/*{*/}
            {/*    !isMobile &&*/}
            {/*    <div className={cl.BetaInfo}*/}
            {/*         style={isBetaInfoBannerHidden ? {display: "none"} : {}}>*/}
            {/*        <div className={cl.BetaInfoContent}>*/}
            {/*            <div className={cl.BetaInfoContentTextContainer}>*/}
            {/*                <div>{ t("ui.betaInfo") }</div>*/}
            {/*                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>*/}
            {/*            </div>*/}
            {/*            <CloseBtn handleClickButton={handleBetaInfoBannerClose}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
}

export default Content;

export function getMemePageLink(id: number) {
    return `${SiteURL}${urlKey}/hot-memes/memes/${id}`
}