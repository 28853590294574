import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react";
import cl from "./PopUpInfo.module.css"
import { useResizing } from "../../hooks/useResizing";

interface PopUpInfoProps {
    openBtnRef: MutableRefObject<HTMLDivElement | null> | MutableRefObject<HTMLImageElement | null>,
    text: string,
    styleProps?: CSSProperties
}

export function PopUpInfo({openBtnRef, text, styleProps}: PopUpInfoProps) {
    const [isPopUpInfoOpen, setIsPopUpInfoOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const popup = popupRef.current;

        function adjustPosition() {
            const popup = popupRef.current;
            if (popup) {
                const rect = popup.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                const windowWidth = window.innerWidth - 22; //22px for scrollbar

                // Корректировка по вертикали. 
                // 5px нужно для запаса чтобы избежать странного глюка с изменением позиции при каждом новом открытии
                if (rect.bottom - 5 > windowHeight) {
                    const offsetY = rect.bottom - windowHeight;
                    popup.style.bottom = `${offsetY}px`;
                }
    
                // Корректировка по горизонтали. 
                // 5px нужно для запаса чтобы избежать странного глюка с изменением позиции при каждом новом открытии
                if (rect.right - 5 > (windowWidth)) {
                    const offsetX = windowWidth - rect.right;
                    popup.style.left = `${offsetX}px`;
                }    
            }
        }

        function handleTransitionEnd(event: TransitionEvent) {
            // Срабатываем только на изменение нужных свойств, например, width, height или font-size
            if (event.propertyName !== "left" && event.propertyName !== "right" && event.propertyName !== "bottom" && event.propertyName !== "top") {
                adjustPosition();
            }
        }

        if (popup) {
            popup.addEventListener("transitionend", handleTransitionEnd);

            return () => {
                popup.removeEventListener("transitionend", handleTransitionEnd);
            };
        }
    }, [isPopUpInfoOpen]);

    function handleClick (event: MouseEvent) {
        //if click outside btn and outside popup
        if (popupRef.current && openBtnRef.current && (!popupRef.current.contains(event.target as Node) && !openBtnRef.current.contains(event.target as Node))) {
            setIsPopUpInfoOpen(false);
        }
        //if click inside btn
        if (openBtnRef.current && openBtnRef.current.contains(event.target as Node)) {
            setIsPopUpInfoOpen(true);
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', handleClick);
        return () => {
            document.removeEventListener('mouseup', handleClick);
        };
    }, []);

    return (
        <div className={cl.PopUpInfo + ` ${isPopUpInfoOpen ? cl.active : ""}`}
            ref={popupRef}
            style={styleProps}>
            { text }
        </div>
    );
}