import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import cl from "./RestorePasswordRequest.module.css"
import {useFetching} from "../../../hooks/useFetching";
import {toast} from "react-toastify";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {Input} from "../../LoginRegister";

function RestorePasswordRequest() {
    const { t } = useTranslation();
    const [queryParams]  = useSearchParams();
    const [email, setEmail] = useState(queryParams.get("email") || "");
    const { authStore } = useContext(Context);

    const [ handleSendEmail, isPending, error] = useFetching(async () => {
        await authStore.sendRestorePasswordEmail(email);
        toast.success(t("profile.advancedSettings.security.emailToRestorePwdSent"), {autoClose: 1000})
    });

    return (
        <div className={cl.Container}>
            <div>{ t("profile.advancedSettings.security.enterEmailToRestorePwd") }</div>
            <form className={cl.FormContainer} onSubmit={(e) => {
                e.preventDefault();
                handleSendEmail({});
            }}>
                <Input
                    type={"text"}
                    name={"memeBattleEmail"}
                    value={email}
                    handleValueChange={e => setEmail(e.target.value)}
                    autoComplete={"email"}
                    placeHolder={t("authForm.login.email")}
                    required={true}
                />
                <button type="submit"
                        className={cl.button}
                        style={(isPending) ? {backgroundColor: "lightgray"} : {}}
                        disabled={isPending}>
                    {isPending ? t("ui.pending") : t("profile.advancedSettings.security.sendRestoreLink")}
                </button>
            </form>
        </div>
    );
}

export default observer(RestorePasswordRequest)