import {JudgeMemeBattle, JudgeMemeBattleResponse, ReportMeme} from "../models/MemeBattle";
import $api from "../http";
import {IBattle} from "../models/Memes";
import {Locale} from "../models/Locale";

export default class MemeBattleService {
    static async getMemeBattleList(locale: Locale) : Promise<IBattle[]> {
        const response =
            await $api.get<IBattle[]>(`/v1/memes/battle?lang=${locale.langCode}&reg=${locale.regCode}&limit=${10}`);
        return response.data;
    }
    static async judgeMemeBattle(data: JudgeMemeBattle, regCode: string) {
        const response =
            await $api.post<JudgeMemeBattleResponse>(`/v1/memes/battle?reg=${regCode}`, data);
        return response.data;
    }
    static async reportMeme(data: ReportMeme) {
        const response =
            await $api.post(`/v1/memes/report`, data);
        return response.data;
    }
    static async titleReport(data: ReportMeme) {
        const response =
            await $api.post(`/v1/memes/title-report`, data);
        return response.data;
    }
}