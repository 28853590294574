import {useFetching} from "../../../hooks/useFetching";
import UserAgreementService from "../../../services/UserAgreementService"
import {useEffect, useState} from "react";
import ErrorPage from "../../ui/ErrorPage";
import Loader from "../../ui/Loader";
import cl from "./UserAgreement.module.css"

export function Privacy() {
    const [privacy, setPrivacy] = useState<string>("");
    const [fetchPrivacy, isLoading, error] = useFetching(async () => {
        const data = await UserAgreementService.getPrivacy()
        setPrivacy(data)
    });

    useEffect(() => {
        fetchPrivacy({})
    }, []);

    return (
        <div className={cl.Container}>
            <div className={cl.Text}
                 dangerouslySetInnerHTML={{__html: privacy}}>
            </div>
            {isLoading && <Loader/>}
            {error && <ErrorPage err={error}/>}
        </div>
    );
}

export function TermsOfUse() {
    const [terms, setTerms] = useState();
    const [fetchTerms, isLoading, error] = useFetching(async () => {
        const data = await UserAgreementService.getTermsOfUse();
        setTerms(data);
    });

    useEffect(() => {
        fetchTerms({})
    }, []);

    return (
        <div className={cl.Container}>
            <div className={cl.Text}>
                <div className={cl.TextTitle}>TERMS OF USE</div>
                <div className={cl.LastUpdated}>Last updated March 30, 2024</div>
                <br/>
                {terms}
            </div>
            {isLoading && <Loader/>}
            {error && <ErrorPage err={error}/>}
        </div>
    );
}