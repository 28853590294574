import block_img from "../../images/profile/block.png";
import unblock_img from "../../images/profile/unblock.png";
import cl from "./Btn.module.css"
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useFetching} from "../../../hooks/useFetching";
import MemeDevService from "../../../services/MemeDevService";
import {toast} from "react-toastify";
import i18n from "../../../ui-translations/i18n";

export interface BlockMemeDevProps {
    id: number,
    isBtnDeactivated?: boolean,
    setIsBtnDeactivated?(b: boolean): void,
    callback?(): void,
}

export function BlockMemeDevBtn({id, isBtnDeactivated, setIsBtnDeactivated, callback}: BlockMemeDevProps) {
    const { t } = useTranslation();
    const [block, isPending, error] = useFetching(async () => {
        if (isPending || isBtnDeactivated) {
            return;
        }
        if (setIsBtnDeactivated) {
            setIsBtnDeactivated(true);
        }
        if (callback) {
            callback();
        }
        await MemeDevService.blockMemeDev(id);
        if (setIsBtnDeactivated) {
            setIsBtnDeactivated(false);
        }
        toast.success(i18n.t("ui.memeDev.memeDevBlocked"), {autoClose: 2000});
    });

    useEffect(() => {
        if (error) {
            toast.error(t("ui.defErrMsg"));
        }
    }, [error]);

    return (
        <div className={cl.MemePanelBtn}
             onClick={() => block({})}>
            <img className={cl.MemePanelBtnImg}
                 src={block_img} alt="block"/>
            <div className={cl.MemePanelBtnTitle}>{t("ui.memeDev.block")}</div>
        </div>
    );
}

export interface UnblockMemeDevProps {
    id: number,
    isBtnDeactivated?: boolean,
    setIsBtnDeactivated?(b: boolean): void,
    callback?(): void,
}

export function UnblockMemeDevBtn({id, isBtnDeactivated, setIsBtnDeactivated, callback}: UnblockMemeDevProps) {
    const { t } = useTranslation();
    const [unblock, isPending, error] = useFetching(async () => {
        if (isPending || isBtnDeactivated) {
            return;
        }
        if (setIsBtnDeactivated) {
            setIsBtnDeactivated(true);
        }
        if (callback) {
            callback();
        }
        await MemeDevService.unblockMemeDev(id);
        if (setIsBtnDeactivated) {
            setIsBtnDeactivated(false);
        }
        toast.success(i18n.t("ui.memeDev.memeDevUnblocked"), {autoClose: 1000});
    });

    useEffect(() => {
        if (error) {
            toast.error(t("ui.defErrMsg"));
        }
    }, [error]);

    return (
        <div className={cl.MemePanelBtn}
             onClick={() => unblock({})}>
            <img className={cl.MemePanelBtnImg}
                 src={unblock_img} alt="unblock"/>
            <div className={cl.MemePanelBtnTitle}>{t("ui.memeDev.unblock")}</div>
        </div>
    );
}