import cl from "./Support.module.css"
import {config} from "../../../config";
import {useTranslation} from "react-i18next";

export function Support() {
    const { t } = useTranslation();
    const supportEmail = config.SupportEmail;
    return (
        <div className={cl.Container}>
            <div className={cl.Title}>
                { t("navbar.support") }
            </div>
            <p>
                { t("support.contactUsViaEmail") }
                <a className={cl.SupportEmailRef}
                   href={`mailto:${supportEmail}`}>
                    {supportEmail}
                </a>
            </p>
            <p>
                { t("support.feelFreeToWriteUs") }
            </p>
        </div>
    );
}