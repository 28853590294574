import React, {useContext, useEffect, useState} from "react";
import cl from "./AdditionalPages.module.css"
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFetching} from "../hooks/useFetching";
import { isDesktop } from "react-device-detect";
import {SiteURL} from "../http";
import {ErrorNotify} from "../components/ui/ErrorPage";
import UrlKey from "../http/urlKey";
import {PwdInput, validationErrorStyle} from "../components/LoginRegister";
import {toast} from "react-toastify";
import {ValidationObject} from "../models/Error";

function RestorePassword() {
    const { authStore } = useContext(Context);
    const { t } = useTranslation();
    const params  = useParams();

    const [password, setPassword] = useState("");
    const [submitPwd, setSubmitPwd] = useState("");

    const [ handleRestore, isPending, error] = useFetching(async () => {
        if (submitPwd !== password) {
            toast.error(t("authForm.signUp.error.pwdDontMatch"));
            return
        }
        const encryptedId = params.id;
        const token = params.token;
        if (encryptedId && token) {
            await authStore.restorePassword({
                user_id: encryptedId,
                token: token,
                new_password: password,
            });
            toast.success(t("profile.advancedSettings.security.pwdUpdateOrRestoreSuccess"), {autoClose: 1000})
        }
    });
    const [failFieldList, setFailFieldList] = useState<string[]>([]);

    useEffect(() => {
        if (error) {
            const props = {allMessages: true, validationObj: "user" as ValidationObject};
            const filedList = ErrorNotify({err: error, validationErrProps: props});
            setFailFieldList(filedList);
        }
    }, [error]);

    return (
        <div className={cl.Background}>
            <div className={cl.Container}>
                <div>{ t("profile.advancedSettings.security.enterNewPwd") }</div>
                <form className={cl.FormContainer} onSubmit={(e) => {
                    e.preventDefault();
                    handleRestore({});
                }}>
                    <PwdInput
                        name={"memeBattleNewPassword"}
                        value={password}
                        handleValueChange={e => setPassword(e.target.value)}
                        placeHolder={t("authForm.signUp.password")}
                        inputStyle={failFieldList.includes("password") ? validationErrorStyle : {}}
                        canShown={true}
                    />
                    <PwdInput
                        name={"submitNewPassword"}
                        value={submitPwd}
                        handleValueChange={e => setSubmitPwd(e.target.value)}
                        placeHolder={t("authForm.signUp.submitPassword")}
                        inputStyle={submitPwd !== password ? validationErrorStyle : {}}
                        canShown={true}
                    />
                    <button type="submit"
                            className={cl.button}
                            style={(isPending) ? {backgroundColor: "lightgray"} : {}}
                            disabled={isPending}>
                        {isPending ? t("ui.pending") : t("profile.advancedSettings.security.changePwd")}
                    </button>
                </form>
                { isDesktop && <a href={SiteURL+UrlKey}>{t("ui.goToWebsite")}</a> }
            </div>
        </div>
    );
}

export default observer(RestorePassword)