import React, {useContext} from "react";
import AppleSignin from 'react-apple-signin-auth';
import {oauthConfig} from "../../../../config";
import {generateBase64UrlEncode} from "../../../../pkg/Strings";
import {jwtDecode} from "jwt-decode";
import {OAuthData} from "../../../../views/LoginRegister";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import cl from "./OAuthBtn.module.css";
import apple_logo_img from "../../../images/login-register/apple_logo.png"

const CLIENT_ID = oauthConfig.AppleClientID;
const SCOPE = 'email name';
const REDIRECT_URI = oauthConfig.AppleRedirectURI;
// Генерация случайного nonce
const generateNonce = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

interface AppleLoginBtnProps {
    getUser: (data: OAuthData) => Promise<void>
}

const AppleLoginButton = observer(({getUser}: AppleLoginBtnProps) => {
    const {localeStore} = useContext(Context);

    return (
        <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
                /** Client ID - eg: 'com.example.com' */
                clientId: CLIENT_ID,
                /** Requested scopes, seperated by spaces - eg: 'email name' */
                scope: SCOPE,
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: REDIRECT_URI,
                /** State string that is returned with the apple response */
                state: generateBase64UrlEncode(),
                /** Nonce */
                nonce: generateNonce(),
                /** Uses popup auth instead of redirection */
                usePopup: true,
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** className */
            className="apple-auth-btn"
            /** Removes default style tag */
            noDefaultStyle={false}
            /** Allows to change the button's children, eg: for changing the button text */
            buttonExtraChildren="Continue with Apple"
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={async (response: any) => {
                // console.log(response);
                // Проверим наличие id_token
                const { id_token } = response.authorization;
                if (id_token) {
                    // Декодируем токен
                    const decodedToken: any = jwtDecode(id_token);
                    // console.log("Decoded token:", decodedToken);

                    // Извлекаем userId, если он существует
                    const userId = decodedToken.sub || "";
                    // console.log("User ID:", userId);
                    const email = decodedToken.email || "";
                    const email_verified = decodedToken.email_verified || false;
                    await getUser({
                        accessToken: id_token,
                        avatarUrl: "",
                        email: email,
                        emailVerified: email_verified,
                        getOAuthData: undefined,
                        lang: localeStore.getMemeLanguage(),
                        name: "",
                        nickName: "",
                        reg: localeStore.getRegion(),
                        type: "apple",
                        userId: userId
                    });
                } else {
                    console.error("ID Token not found in response");
                }
            }} // default = undefined
            /** Called upon signin error */
            onError={(error: any) => console.error(error)} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** Apple image props */
            // iconProps={{style: {marginTop: '10px'}}} // default = undefined
            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
            render={(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLDivElement> & React.ButtonHTMLAttributes<HTMLDivElement>) =>
                <div {...props} className={cl.OAuthButton}
                     onClick={props.onClick}>
                    <div className={cl.OAuthImgContainer}>
                        <img className={cl.OAuthAppleLogoImg} src={apple_logo_img} alt={"login with apple"}/>
                    </div>
                </div>
            }
        />
    );
});

export default AppleLoginButton;
