import hashtag_img from "../../images/meme/hashtag.png";
import cl from "./Btn.module.css"
import React from "react";

export interface ShowTagsBtnProps {
    title?: string,
    callback(): void,
}

export default function ShowTagsBtn({title, callback}: ShowTagsBtnProps) {
    return (
        <div className={cl.MemePanelBtn}
             onClick={() => callback()}>
            <img className={cl.MemePanelBtnImg}
                 src={hashtag_img} alt="hashtag"/>
            { title && <div className={cl.MemePanelBtnTitle}>{title}</div> }
        </div>
    );
}