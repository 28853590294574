import cl from "./Btn.module.css"
import expMemeImg from "../../images/meme/download.png"
import html2canvas from "html2canvas";
import React, {MutableRefObject, useRef, useState} from "react";
import {Title} from "../../content/Memes/Memes";
import {MemeDataToExport} from "../../../views/Memes";
import {useTranslation} from "react-i18next";
import {Size} from "../../content/Builder/Constructor/Title/TitleStore";
import {toast} from "react-toastify";
import i18n from "i18next";
import {sendMetric} from "../../../pkg/Metric";

export interface ExportMemeBtnProps {
    meme: MemeDataToExport,
    fileName: string,
    specifyMetricName?: string,
}

function loadImageAsync(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
            resolve(image);
        };

        image.onerror = (error) => {
            reject(new Error("Error loading image: " + url));
        };
    });
}

export default function ExportMemeBtn(props: ExportMemeBtnProps) {
    const { t } = useTranslation();
    const [shouldRender, setShouldRender] = useState(false);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const imgContainer = useRef<HTMLDivElement | null>(null);
    const [imgContainerSize, setImgContainerSize] = useState<Size | null>(null);

    async function handleClick() {
        toast.info(t("ui.imageExportMsg"), {autoClose: 1000});
        setShouldRender(true);
        await handleSetImage();
        setTimeout(() => {
            handleMemeExport(imgContainer, props.fileName, () => setShouldRender(false));
            if (props.specifyMetricName) {
                sendMetric("goal", props.specifyMetricName);
            } else {
                sendMetric("goal", "download_meme")
            }
        }, 300); //timeout to have time to render the component
    }

    async function handleSetImage() {
        try {
            const img = await loadImageAsync(props.meme.url)
            setImage(img);
            if (img.width < 1000) {
                setImgContainerSize({width: 1000, height: img.height*(1000/img.width)});
            } else if (img.height < 1000) {
                setImgContainerSize({width: img.width*(1000/img.height), height: 1000});
            } else {
                setImgContainerSize({width: img.width, height: img.height});
            }
        } catch (e) {
            toast.error(t("ui.defErrMsg"));
            console.log(e as Error);
        }
    }

    return (
        <div className={cl.MemePanelBtn}>
            <img className={cl.MemePanelBtnImg}
                 src={expMemeImg}
                 alt={"export-meme"}
                 onClick={handleClick}/>
            {shouldRender && image &&
                <div style={{zIndex: -1, opacity: 0.001, position: "fixed", top: 0, left: 0}}>
                    <div style={{position: "relative", ...imgContainerSize}}
                         ref={imgContainer}>
                        <img src={image.src} alt={"meme_img"} style={{width: "100%"}}/>
                        {props.meme.titles.map((title, index) =>
                            <Title title={title}
                                   key={`${props.meme.id}-${index}`}
                                   imgContainerSize={imgContainerSize}
                            />)}
                    </div>
                </div>
            }
        </div>
    );
}

const {saveAs} = require('file-saver');

export async function handleMemeExport (
    imgContainer: MutableRefObject<HTMLDivElement | null>,
    fileName: string,
    afterExport: () => void)
{
    if (imgContainer.current) {
        // Set the container dimensions so that html2canvas can correctly place absolute positioned elements within it
        const width = imgContainer.current?.clientWidth;
        const height = imgContainer.current?.clientHeight;
        imgContainer.current.style.width = `${width}px`;
        imgContainer.current.style.height = `${height}px`;
        imgContainer.current.style.position = `relative`;
        const watermark = document.createElement("div");
        watermark.textContent="meme-battle.com";
        if (height < width) {
            watermark.style.fontSize = `${0.05 * height}px`
        } else {
            watermark.style.fontSize = `${0.05 * width}px`
        }
        watermark.classList.add(cl.Watermark);
        imgContainer.current.appendChild(watermark);

        try {
            const canvas = await html2canvas(imgContainer.current, { useCORS: true });
            const dataUrl = canvas.toDataURL('image/jpeg');
            const blob = dataURItoBlob(dataUrl);
            saveAs(blob, `${fileName}.jpg`);
        } catch (e) {
            console.log(e as Error);
            toast.error(i18n.t("ui.defErrMsg"));
        } finally {
            afterExport();
        }
    }
}

function dataURItoBlob (dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}