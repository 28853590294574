import {SVGIconProps} from "../svgIcon";

export interface Form {
    svg(props: SVGIconProps): JSX.Element,
    formType: FormType,
}

export type FormType = "x_x" | "1_1" | "1_2" | "1_3" | "1_4" | "2_1" | "2_2" | "2_3" | "2_4" | "own"

const forms : Form[] = [
    {svg: FormXX, formType: "x_x"},
    {svg: Form11, formType: "1_1"},
    {svg: Form12, formType: "1_2"},
    {svg: Form13, formType: "1_3"},
    {svg: Form14, formType: "1_4"},
    {svg: Form21, formType: "2_1"},
    {svg: Form22, formType: "2_2"},
    {svg: Form23, formType: "2_3"},
    {svg: Form24, formType: "2_4"},
    {svg: FormOwn, formType: "own"},
];

export default forms;

export function FormXX({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                stroke={color}
                strokeDasharray="none"
                strokeLinejoin="round"
                strokeOpacity="1"
                transform="translate(93.164 -156.78)"
            >
                <path
                    fillOpacity="1"
                    fillRule="nonzero"
                    strokeLinecap="butt"
                    strokeWidth="2"
                    d="M-88.572 157.78h26.771a3.685 3.685 45 013.685 3.69v27.14a3.218 3.218 135 01-3.218 3.22h-27.425a3.405 3.405 45 01-3.405-3.41v-27.05a3.592 3.592 135 013.592-3.59z"
                    paintOrder="normal"
                ></path>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "0" }}
                    x="-82.351"
                    y="180.1"
                    strokeLinecap="round"
                    strokeWidth="0.9"
                    fontSize="14.111"
                >
                    <tspan
                        style={{ lineHeight: "0" }}
                        x="-82.351"
                        y="180.1"
                        fill={color}
                        fillOpacity="1"
                        stroke={color}
                        strokeDasharray="none"
                        strokeLinejoin="round"
                        strokeOpacity="1"
                        strokeWidth="0.9"
                        fontFamily="Open Sans"
                        fontSize="14.111"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                    >
                        all
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

export function Form11({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g transform="translate(-7.489 -156.97)">
                <path
                    fill="none"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke={color}
                    strokeDasharray="none"
                    strokeLinecap="butt"
                    strokeLinejoin="round"
                    strokeOpacity="1"
                    strokeWidth="2"
                    d="M11.894 157.97h27.332a3.311 3.311 45 013.311 3.31v27.43a3.311 3.311 135 01-3.311 3.31H12.081a3.592 3.592 45 01-3.592-3.59v-27.06a3.405 3.405 135 013.405-3.4z"
                    paintOrder="normal"
                ></path>
            </g>
        </svg>
    );
}

export function Form12({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.5 36.5"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-58.138 -156.93)"
            >
                <path d="M62.411 157.93h27.238a3.537 3.537 45 013.537 3.54v27.24a3.272 3.272 135 01-3.272 3.27H62.94a3.802 3.802 45 01-3.802-3.8V161.2a3.272 3.272 135 013.273-3.27z"></path>
                <path d="M59.165 174.97h34.097"></path>
            </g>
        </svg>
    );
}

export function Form13({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-106.63 -156.9)"
            >
                <path d="M107.2 181.07h34.1"></path>
                <path d="M107.5 168.9h34.09"></path>
                <path d="M110.9 157.896h27.238a3.537 3.537 0 013.537 3.54v27.24a3.272 3.272 0 01-3.272 3.27H111.43a3.802 3.802 0 01-3.802-3.8v-26.98a3.272 3.272 0 013.273-3.27z"></path>
            </g>
        </svg>
    );
}

export function Form14({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-156.9 -157.03)"
            >
                <path d="M161.17 158.03h27.11a3.67 3.67 45 013.67 3.67v26.71a3.67 3.67 135 01-3.67 3.67H161.7a3.802 3.802 45 01-3.8-3.8V161.3a3.272 3.272 135 013.27-3.27z"></path>
                <path d="M157.92 175.06h34.1"></path>
                <path d="M157.96 166.42h34.1"></path>
                <path d="M158.06 183.7h34.1"></path>
            </g>
        </svg>
    );
}

export function Form21({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-215.91 -156.37)"
            >
                <path d="M220.18 157.367h27.238a3.537 3.537 0 013.537 3.54v27.24a3.272 3.272 0 01-3.272 3.27H220.71a3.802 3.802 0 01-3.802-3.8v-26.98a3.272 3.272 0 013.273-3.27z"></path>
                <path d="M233.89 157.29v34"></path>
            </g>
        </svg>
    );
}

export function Form22({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-215.64 -156.9)"
            >
                <path d="M219.91 157.896h27.238a3.537 3.537 0 013.537 3.54v27.24a3.272 3.272 0 01-3.272 3.27H220.44a3.802 3.802 0 01-3.802-3.8v-26.98a3.272 3.272 0 013.273-3.27z"></path>
                <path d="M216.66 174.94h34.1"></path>
                <path d="M233.62 157.82v34"></path>
            </g>
        </svg>
    );
}

export function Form23({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-263.86 -156.99)"
            >
                <path d="M267.239 157.986h28.92a2.743 2.743 0 012.75 2.74v28.04a3.272 3.272 0 01-3.27 3.27h-26.98a3.802 3.802 0 01-3.8-3.8v-27.87a2.376 2.376 0 012.38-2.38z"></path>
                <path d="M264.7 181.04h34.1"></path>
                <path d="M265 168.87h34.09"></path>
                <path d="M281.83 157.82v34.13"></path>
            </g>
        </svg>
    );
}

export function Form24({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                fillOpacity="1"
                fillRule="nonzero"
                stroke={color}
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeWidth="2"
                paintOrder="normal"
                transform="translate(-314.4 -157)"
            >
                <path d="M318.668 157.996h27.11a3.67 3.67 0 013.67 3.67v26.71a3.67 3.67 0 01-3.67 3.67h-26.58a3.802 3.802 0 01-3.8-3.8v-26.98a3.272 3.272 0 013.27-3.27z"></path>
                <path d="M315.42 175.03h34.1"></path>
                <path d="M315.46 166.39h34.1"></path>
                <path d="M315.56 183.67h34.1"></path>
                <path d="M332.37 157.89v34"></path>
            </g>
        </svg>
    );
}

export function FormOwn({color, size}: SVGIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            version="1.1"
            viewBox="0 0 36.048 36.048"
        >
            <defs>
                <linearGradient>
                    <stop offset="0" stopColor="#000" stopOpacity="1"></stop>
                </linearGradient>
            </defs>
            <g
                fill="none"
                stroke={color}
                strokeDasharray="none"
                strokeLinejoin="round"
                strokeOpacity="1"
                transform="translate(44.035 -156.8)"
            >
                <path
                    fillOpacity="1"
                    fillRule="nonzero"
                    strokeLinecap="butt"
                    strokeWidth="2"
                    d="M-39.724 157.8h27.145a3.592 3.592 45 013.592 3.59v27.33a3.124 3.124 135 01-3.124 3.13H-39.35a3.685 3.685 45 01-3.685-3.69v-27.05a3.311 3.311 135 013.311-3.31z"
                    paintOrder="normal"
                ></path>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "0" }}
                    x="-39.778"
                    y="178.6"
                    strokeLinecap="round"
                    strokeWidth="0.9"
                    fontSize="14.111"
                >
                    <tspan
                        style={{ lineHeight: "0" }}
                        x="-39.778"
                        y="178.6"
                        fill={color}
                        fillOpacity="1"
                        stroke={color}
                        strokeDasharray="none"
                        strokeLinejoin="round"
                        strokeOpacity="1"
                        strokeWidth="0.9"
                        fontFamily="Open Sans"
                        fontSize="14.111"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                    >
                        own
                    </tspan>
                </text>
            </g>
        </svg>
    );
}
