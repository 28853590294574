import {Bounce, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {isMobile} from "react-device-detect";

export function Toast() {
    const minWidth = isMobile ? "90%" : "500px";
    return (
        <ToastContainer
            style={{fontSize: "var(--smaller-font-size)", minWidth: minWidth}}
            position="bottom-center"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}/>
    );
}