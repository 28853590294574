import {MutableRefObject, useEffect, useRef} from "react";

export function useObserving(ref: MutableRefObject<HTMLDivElement | null>, canLoad: boolean, isLoading: boolean, callback: () => void) {
    const observer = useRef<IntersectionObserver | null>(null);
    useEffect(() => {
        if(isLoading) return;
        if(observer.current) observer.current?.disconnect();
        const handle = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            if (entries[0].isIntersecting && canLoad) {
                callback();
            }
        };
        observer.current = new IntersectionObserver(handle);
        if (ref.current) {
            observer.current?.observe(ref.current as HTMLDivElement);
        }
        // return () => {
        //     observer.current?.disconnect();
        // };
    }, [isLoading]);
}