import React, {useContext, useEffect} from 'react';
import {generateBase64UrlEncode, getURLWithQueryParams} from "../../../../pkg/Strings";
import {oauthConfig} from "../../../../config";
import axios from "axios";
import pkceChallenge from "pkce-challenge";
import {OAuthData} from "../../../../views/LoginRegister";
import {AxiosResponse} from "axios";
import {DiscordUserInfo} from "../../../../models/Auth";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import cl from "./OAuthBtn.module.css";
import discord_logo_img from "../../../images/login-register/discord_logo.png"

const CLIENT_ID = oauthConfig.DiscordClientID;
const REDIRECT_URI = oauthConfig.DiscordRedirectURI;
const AUTH_URL = 'https://discord.com/api/oauth2/authorize';
const SCOPE = 'identify email';

const getDiscordOAuthUrl = (challenge: string) =>
    getURLWithQueryParams(AUTH_URL, {
        response_type: "code",
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        scope: SCOPE,
        state: generateBase64UrlEncode(),
        code_challenge: challenge,
        code_challenge_method: "S256",
    })

interface DiscordLoginBtnProps {
    getUser: (data: OAuthData) => Promise<void>
}

export const DiscordLoginButton = observer(({getUser}: DiscordLoginBtnProps) => {
    const {localeStore} = useContext(Context);
    const openDiscordOAuthPopup = async () => {
        const width = 500;
        const height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const { code_verifier, code_challenge } = await pkceChallenge();
        localStorage.setItem('code_verifier', code_verifier);

        const discordOAuthUrl = getDiscordOAuthUrl(code_challenge);
        // Открываем новое окно для твиттера
        const popup = window.open(
            discordOAuthUrl,
            'DiscordOAuth',
            `width=${width},height=${height},top=${top},left=${left}`
        );

        // Проверяем, закрылось ли popup окно
        const intervalId = setInterval(() => {
            if (popup && popup.closed) {
                clearInterval(intervalId);
                console.log('Авторизация завершена или окно закрыто');
                localStorage.removeItem("code_verifier")
            }
        }, 500);
    };

    // Функция для получения кода из URL, когда popup окно делает редирект
    const receiveMessageDiscord = async (event: MessageEvent) => {
        if (event.origin !== window.location.origin) return; // Проверяем, что сообщение пришло с того же домена

        const { code, provider } = event.data;
        if (code && provider === "discord") {
            // console.log('Получен код авторизации:', code);
            try {
                const oauthData = await onSuccessDiscordLogin(code, localeStore.getMemeLanguage(), localeStore.getRegion());
                await getUser(oauthData);
            } catch (error) {
                console.error('Ошибка при получении access_token или user_info:', error);
            }
        }
    };
    useEffect(() => {
        // Подписываемся на события postMessage
        window.addEventListener('message', receiveMessageDiscord);

        return () => {
            // Убираем подписку на события при размонтировании компонента
            window.removeEventListener('message', receiveMessageDiscord);
            localStorage.removeItem("code_verifier")
        };
    }, []);

    return (
        <div onClick={() => openDiscordOAuthPopup()}
             className={cl.OAuthButton}>
            <div className={cl.OAuthImgContainer}>
                <img className={cl.OAuthLogoImg} src={discord_logo_img} alt={"login with discord"}/>
            </div>
        </div>
    );
})

async function onSuccessDiscordLogin(code: string, lang: string, reg: string): Promise<OAuthData> {
    const resToken = await axios.post('https://discord.com/api/oauth2/token', {
        grant_type: 'authorization_code',
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        code: code,
        code_verifier: localStorage.getItem("code_verifier"),
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    const { access_token } = resToken.data;
    // Теперь можно получить данные пользователя
    const resUserInfo: AxiosResponse<DiscordUserInfo> = await axios.get('https://discord.com/api/users/@me', {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    // console.log(resUserInfo);
    return {
        type: "discord",
        userId: resUserInfo.data.id,
        accessToken: access_token,
        avatarUrl: resUserInfo.data.avatar || "",
        email: resUserInfo.data.email || "",
        emailVerified: resUserInfo.data.verified,
        name: "",
        nickName: resUserInfo.data.username || "",
        lang: lang,
        reg: reg,
        getOAuthData: undefined
    };
}