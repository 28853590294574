import {Language, Region} from "../models/Locale";
import $api from "../http";

export default class LocaleService {
    static async getLanguages() {
        const res = await $api.get<Language[]>("/v1/locales/langs")
        return res.data
    }

    static async getRegions() {
        const res = await $api.get<Region[]>("/v1/locales/regs")
        return res.data
    }
}